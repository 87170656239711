import axiosService from "../../axiosService";

export const getIngredientsApi = () => {
    const url = "/cms/ingredients"
    return axiosService.get(url)
}

export const getIngredientApi = (id) => {
    const url = `/cms/ingredient/${id}`
    return axiosService.get(url)
}

export const getIngredientFamiliesApi = () => {
    const url = `/cms/ingredient-families`
    return axiosService.get(url)
}

export const getAllergensApi = () => {
    const url = `/cms/allergens`
    return axiosService.get(url)
}

export const deleteIngredientApi = (ingredient) => {
    const url = `/cms/ingredient/${ingredient}`;
    return axiosService.delete(url)
}

export const getCookingTypesApi = () => {
    const url = "/cms/cooking-types"
    return axiosService.get(url)
}

export const getCookingTypesForIngredientApi = (ingredient) => {
    const url = `/cms/cooking-types/${ingredient}`;
    return axiosService.get(url)
}

export const saveIngredientApi = (id = null, ingredient) => {
    console.log(ingredient)
    let url = `/cms/ingredient/`;
    if (id !== null) {
        url = `/cms/ingredient/${id}`;
        return axiosService.put(url, ingredient)
    }
    return axiosService.post(url, ingredient)
}
