import React from "react";
import styled from "styled-components/macro";
import {InputBase} from "@mui/material";

import {Search as SearchIcon} from "react-feather";
import {darken} from "polished";

const Search = styled.div`
    border-radius: 4px;
    border: 1px solid rgba(224, 224, 224, 1);
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 130%;

    &:hover {
        background-color: ${(props) => darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("xs")} {
        display: block;
    }
`;

const SearchIconWrapper = styled.div`
    width: 40px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 20px;
        height: 20px;
    }
`;

const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
        color: ${(props) => props.theme.header.search.color};
        padding-top: ${(props) => props.theme.spacing(2.5)};
        padding-right: ${(props) => props.theme.spacing(2.5)};
        padding-bottom: ${(props) => props.theme.spacing(2.5)};
        padding-left: 40px;
        width: 100%;
    }
`;

const Searchbar = ({placeholder, onChange}) => {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon/>
            </SearchIconWrapper>
            <Input placeholder={placeholder} onChange={onChange}/>
        </Search>
    );
};
export default Searchbar;
