import React from "react";
import {useRoutes, useNavigate} from "react-router-dom";
import {Provider} from "react-redux";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {create} from "jss";
import {ThemeProvider} from "styled-components/macro";

import {StyledEngineProvider} from "@mui/styled-engine-sc";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import {store} from "./redux/store";

import {AuthProvider} from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
import moment from 'moment';
import localization from 'moment/locale/es';

moment.updateLocale('es', localization);
const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-insertion-point"),
});


function App() {
    const content = useRoutes(routes);

    const {theme} = useTheme();
    let history = useNavigate();

    if (!window.reactNavigate) {
        window.reactNavigate = history;
    }

    return (
        <HelmetProvider>
            <Helmet
                titleTemplate="La Menuteka CMS"
                defaultTitle="Gestor de contenido de La Menuteka"
            />
            <Provider store={store}>
                <StylesProvider jss={jss}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StyledEngineProvider injectFirst>
                            <MuiThemeProvider theme={createTheme(theme)}>
                                <ThemeProvider theme={createTheme(theme)}>
                                    <AuthProvider>{content}</AuthProvider>
                                </ThemeProvider>
                            </MuiThemeProvider>
                        </StyledEngineProvider>
                    </LocalizationProvider>
                </StylesProvider>
            </Provider>
        </HelmetProvider>
    );
}

export default App;
