import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {spacing} from "@mui/system";
import {TextField as MuiTextField, Typography} from "@mui/material";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

const TextField = styled(MuiTextField)(spacing);
const filter = createFilterOptions();

const AutocompleteSelect = ({value, options, onChange, label, isAddingOption}) => {
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue])

    useEffect(() => {
        if (!!value) {
            setSelectedValue(value)
        }
    }, [value])

    function handleSelect(event, newValue) {
        setSelectedValue(newValue?.optionValue);

        if (typeof newValue === "object") {
            setSelectedValue(newValue)
            return
        }

        if (typeof newValue === "string") {
            setSelectedValue(newValue);
        } else if (newValue && newValue.inputValue) {
            setSelectedValue(newValue.inputValue);
        }
    }

    return (
        <div style={{width: '100%'}}>
            {label && <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{label}</Typography>}
            <Autocomplete
                value={selectedValue}
                fullWidth
                onChange={handleSelect}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const {inputValue} = params;

                    const isExisting = options.some(
                        (option) => inputValue === option.optionValue
                    );
                    if (inputValue !== "" && !isExisting && isAddingOption) {
                        filtered.push({
                            inputValue,
                            optionValue: `${inputValue}`,
                        });
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="optionValue"
                name="optionValue"
                options={options}
                getOptionLabel={(option) => {
                    if (typeof option === "string") {
                        return option;
                    }

                    if (typeof option === "number") {
                        return option.toString();
                    }

                    if (typeof option === "object" && !!option.optionValue) {
                        return option.optionValue;
                    }

                    if (typeof option === "object" && !option.optionValue) {
                        return "";
                    }

                    if (option.inputValue) {
                        return option.inputValue;
                    }

                    return option.optionValue;
                }}
                renderOption={(props, option) => (
                    <li {...props}>{option.optionValue}</li>
                )}
                freeSolo
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            placeholder={label}
                            name="optionValue"
                        />
                    );
                }}
            />
        </div>
    );
};
export default AutocompleteSelect;
