import axiosService from "../../axiosService";


export const recoverPasswordApi = (recoveryToken, newPassword) => {

    const url = "/auth/set-password"

    return axiosService.post(url, {
        recoveryToken: recoveryToken,
        newPassword: newPassword
    })
}


export const sendEmailApi = (email) => {

    const url = "/auth/recover-password"

    return axiosService.post(url, {
        email: email,
    })
}
