import * as React from "react";
import styled, {withTheme} from "styled-components/macro";

import {AppBar as MuiAppBar, Grid, IconButton as MuiIconButton, Toolbar,} from "@mui/material";

import {Menu as MenuIcon} from "@mui/icons-material";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const NavbarSimple = ({onDrawerToggle}) => {
    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item sx={{display: {xs: "block", md: "none"}}}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={onDrawerToggle}
                                size="large"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default withTheme(NavbarSimple);
