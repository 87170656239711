import React from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    Grid,
    Link as MuiLink,
    Paper as MuiPaper,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";
import * as Icon from "@mui/icons-material";

const icons = {
    AccessAlarm: <Icon.AccessAlarm/>,
    AccessAlarms: <Icon.AccessAlarms/>,
    Accessibility: <Icon.Accessibility/>,
    AccessibilityNew: <Icon.AccessibilityNew/>,
    Accessible: <Icon.Accessible/>,
    AccessibleForward: <Icon.AccessibleForward/>,
    AccessTime: <Icon.AccessTime/>,
    AccountBalance: <Icon.AccountBalance/>,
    AccountBalanceWallet: <Icon.AccountBalanceWallet/>,
    AccountBox: <Icon.AccountBox/>,
    AccountCircle: <Icon.AccountCircle/>,
    AcUnit: <Icon.AcUnit/>,
    Adb: <Icon.Adb/>,
    Add: <Icon.Add/>,
    AddAlarm: <Icon.AddAlarm/>,
    AddAlert: <Icon.AddAlert/>,
    AddAPhoto: <Icon.AddAPhoto/>,
    AddBox: <Icon.AddBox/>,
    AddCircle: <Icon.AddCircle/>,
    AddCircleOutline: <Icon.AddCircleOutline/>,
    AddComment: <Icon.AddComment/>,
    AddLocation: <Icon.AddLocation/>,
    AddPhotoAlternate: <Icon.AddPhotoAlternate/>,
    AddShoppingCart: <Icon.AddShoppingCart/>,
    AddToHomeScreen: <Icon.AddToHomeScreen/>,
    AddToPhotos: <Icon.AddToPhotos/>,
    AddToQueue: <Icon.AddToQueue/>,
    Adjust: <Icon.Adjust/>,
    AirlineSeatFlat: <Icon.AirlineSeatFlat/>,
    AirlineSeatFlatAngled: <Icon.AirlineSeatFlatAngled/>,
    AirlineSeatIndividualSuite: <Icon.AirlineSeatIndividualSuite/>,
    AirlineSeatLegroomExtra: <Icon.AirlineSeatLegroomExtra/>,
    AirlineSeatLegroomNormal: <Icon.AirlineSeatLegroomNormal/>,
    AirlineSeatLegroomReduced: <Icon.AirlineSeatLegroomReduced/>,
    AirlineSeatReclineExtra: <Icon.AirlineSeatReclineExtra/>,
    AirlineSeatReclineNormal: <Icon.AirlineSeatReclineNormal/>,
    AirplanemodeActive: <Icon.AirplanemodeActive/>,
    AirplanemodeInactive: <Icon.AirplanemodeInactive/>,
    Airplay: <Icon.Airplay/>,
    AirportShuttle: <Icon.AirportShuttle/>,
    Alarm: <Icon.Alarm/>,
    AlarmAdd: <Icon.AlarmAdd/>,
    AlarmOff: <Icon.AlarmOff/>,
    AlarmOn: <Icon.AlarmOn/>,
    Album: <Icon.Album/>,
    AllInbox: <Icon.AllInbox/>,
    AllInclusive: <Icon.AllInclusive/>,
    AllOut: <Icon.AllOut/>,
    AlternateEmail: <Icon.AlternateEmail/>,
    Android: <Icon.Android/>,
    Announcement: <Icon.Announcement/>,
    Apps: <Icon.Apps/>,
    Archive: <Icon.Archive/>,
    ArrowBack: <Icon.ArrowBack/>,
    ArrowBackIos: <Icon.ArrowBackIos/>,
    ArrowDownward: <Icon.ArrowDownward/>,
    ArrowDropDown: <Icon.ArrowDropDown/>,
    ArrowDropDownCircle: <Icon.ArrowDropDownCircle/>,
    ArrowDropUp: <Icon.ArrowDropUp/>,
    ArrowForward: <Icon.ArrowForward/>,
    ArrowForwardIos: <Icon.ArrowForwardIos/>,
    ArrowLeft: <Icon.ArrowLeft/>,
    ArrowRight: <Icon.ArrowRight/>,
    ArrowRightAlt: <Icon.ArrowRightAlt/>,
    ArrowUpward: <Icon.ArrowUpward/>,
    ArtTrack: <Icon.ArtTrack/>,
    AspectRatio: <Icon.AspectRatio/>,
    Assessment: <Icon.Assessment/>,
    Assignment: <Icon.Assignment/>,
    AssignmentInd: <Icon.AssignmentInd/>,
    AssignmentLate: <Icon.AssignmentLate/>,
    AssignmentReturn: <Icon.AssignmentReturn/>,
    AssignmentReturned: <Icon.AssignmentReturned/>,
    AssignmentTurnedIn: <Icon.AssignmentTurnedIn/>,
    Assistant: <Icon.Assistant/>,
    AssistantPhoto: <Icon.AssistantPhoto/>,
    Atm: <Icon.Atm/>,
    AttachFile: <Icon.AttachFile/>,
    Attachment: <Icon.Attachment/>,
    AttachMoney: <Icon.AttachMoney/>,
    Audiotrack: <Icon.Audiotrack/>,
    Autorenew: <Icon.Autorenew/>,
    AvTimer: <Icon.AvTimer/>,
    Backspace: <Icon.Backspace/>,
    Backup: <Icon.Backup/>,
    Ballot: <Icon.Ballot/>,
    BarChart: <Icon.BarChart/>,
    Battery20: <Icon.Battery20/>,
    Battery30: <Icon.Battery30/>,
    Battery50: <Icon.Battery50/>,
    Battery60: <Icon.Battery60/>,
    Battery80: <Icon.Battery80/>,
    Battery90: <Icon.Battery90/>,
    BatteryAlert: <Icon.BatteryAlert/>,
    BatteryCharging20: <Icon.BatteryCharging20/>,
    BatteryCharging30: <Icon.BatteryCharging30/>,
    BatteryCharging50: <Icon.BatteryCharging50/>,
    BatteryCharging60: <Icon.BatteryCharging60/>,
    BatteryCharging80: <Icon.BatteryCharging80/>,
    BatteryCharging90: <Icon.BatteryCharging90/>,
    BatteryChargingFull: <Icon.BatteryChargingFull/>,
    BatteryFull: <Icon.BatteryFull/>,
    BatteryStd: <Icon.BatteryStd/>,
    BatteryUnknown: <Icon.BatteryUnknown/>,
    BeachAccess: <Icon.BeachAccess/>,
    Beenhere: <Icon.Beenhere/>,
    Block: <Icon.Block/>,
    Bluetooth: <Icon.Bluetooth/>,
    BluetoothAudio: <Icon.BluetoothAudio/>,
    BluetoothConnected: <Icon.BluetoothConnected/>,
    BluetoothDisabled: <Icon.BluetoothDisabled/>,
    BluetoothSearching: <Icon.BluetoothSearching/>,
    BlurCircular: <Icon.BlurCircular/>,
    BlurLinear: <Icon.BlurLinear/>,
    BlurOff: <Icon.BlurOff/>,
    BlurOn: <Icon.BlurOn/>,
    Book: <Icon.Book/>,
    Bookmark: <Icon.Bookmark/>,
    BookmarkBorder: <Icon.BookmarkBorder/>,
    Bookmarks: <Icon.Bookmarks/>,
    BorderAll: <Icon.BorderAll/>,
    BorderBottom: <Icon.BorderBottom/>,
    BorderClear: <Icon.BorderClear/>,
    BorderColor: <Icon.BorderColor/>,
    BorderHorizontal: <Icon.BorderHorizontal/>,
    BorderInner: <Icon.BorderInner/>,
    BorderLeft: <Icon.BorderLeft/>,
    BorderOuter: <Icon.BorderOuter/>,
    BorderRight: <Icon.BorderRight/>,
    BorderStyle: <Icon.BorderStyle/>,
    BorderTop: <Icon.BorderTop/>,
    BorderVertical: <Icon.BorderVertical/>,
    BrandingWatermark: <Icon.BrandingWatermark/>,
    Brightness1: <Icon.Brightness1/>,
    Brightness2: <Icon.Brightness2/>,
    Brightness3: <Icon.Brightness3/>,
    Brightness4: <Icon.Brightness4/>,
    Brightness5: <Icon.Brightness5/>,
    Brightness6: <Icon.Brightness6/>,
    Brightness7: <Icon.Brightness7/>,
    BrightnessAuto: <Icon.BrightnessAuto/>,
    BrightnessHigh: <Icon.BrightnessHigh/>,
    BrightnessLow: <Icon.BrightnessLow/>,
    BrightnessMedium: <Icon.BrightnessMedium/>,
    BrokenImage: <Icon.BrokenImage/>,
    Brush: <Icon.Brush/>,
    BubbleChart: <Icon.BubbleChart/>,
    BugReport: <Icon.BugReport/>,
    Build: <Icon.Build/>,
    BurstMode: <Icon.BurstMode/>,
    Business: <Icon.Business/>,
    BusinessCenter: <Icon.BusinessCenter/>,
    Cached: <Icon.Cached/>,
    Cake: <Icon.Cake/>,
    CalendarToday: <Icon.CalendarToday/>,
    CalendarViewDay: <Icon.CalendarViewDay/>,
    Call: <Icon.Call/>,
    CallEnd: <Icon.CallEnd/>,
    CallMade: <Icon.CallMade/>,
    CallMerge: <Icon.CallMerge/>,
    CallMissed: <Icon.CallMissed/>,
    CallMissedOutgoing: <Icon.CallMissedOutgoing/>,
    CallReceived: <Icon.CallReceived/>,
    CallSplit: <Icon.CallSplit/>,
    CallToAction: <Icon.CallToAction/>,
    Camera: <Icon.Camera/>,
    CameraAlt: <Icon.CameraAlt/>,
    CameraEnhance: <Icon.CameraEnhance/>,
    CameraFront: <Icon.CameraFront/>,
    CameraRear: <Icon.CameraRear/>,
    CameraRoll: <Icon.CameraRoll/>,
    Cancel: <Icon.Cancel/>,
    CancelPresentation: <Icon.CancelPresentation/>,
    CardGiftcard: <Icon.CardGiftcard/>,
    CardMembership: <Icon.CardMembership/>,
    CardTravel: <Icon.CardTravel/>,
    Casino: <Icon.Casino/>,
    Cast: <Icon.Cast/>,
    CastConnected: <Icon.CastConnected/>,
    CastForEducation: <Icon.CastForEducation/>,
    Category: <Icon.Category/>,
    CellWifi: <Icon.CellWifi/>,
    CenterFocusStrong: <Icon.CenterFocusStrong/>,
    CenterFocusWeak: <Icon.CenterFocusWeak/>,
    ChangeHistory: <Icon.ChangeHistory/>,
    Chat: <Icon.Chat/>,
    ChatBubble: <Icon.ChatBubble/>,
    ChatBubbleOutline: <Icon.ChatBubbleOutline/>,
    Check: <Icon.Check/>,
    CheckBox: <Icon.CheckBox/>,
    CheckBoxOutlineBlank: <Icon.CheckBoxOutlineBlank/>,
    CheckCircle: <Icon.CheckCircle/>,
    CheckCircleOutline: <Icon.CheckCircleOutline/>,
    ChevronLeft: <Icon.ChevronLeft/>,
    ChevronRight: <Icon.ChevronRight/>,
    ChildCare: <Icon.ChildCare/>,
    ChildFriendly: <Icon.ChildFriendly/>,
    ChromeReaderMode: <Icon.ChromeReaderMode/>,
    Class: <Icon.Class/>,
    Clear: <Icon.Clear/>,
    ClearAll: <Icon.ClearAll/>,
    Close: <Icon.Close/>,
    ClosedCaption: <Icon.ClosedCaption/>,
    Cloud: <Icon.Cloud/>,
    CloudCircle: <Icon.CloudCircle/>,
    CloudDone: <Icon.CloudDone/>,
    CloudDownload: <Icon.CloudDownload/>,
    CloudOff: <Icon.CloudOff/>,
    CloudQueue: <Icon.CloudQueue/>,
    CloudUpload: <Icon.CloudUpload/>,
    Code: <Icon.Code/>,
    Collections: <Icon.Collections/>,
    CollectionsBookmark: <Icon.CollectionsBookmark/>,
    Colorize: <Icon.Colorize/>,
    ColorLens: <Icon.ColorLens/>,
    Comment: <Icon.Comment/>,
    Commute: <Icon.Commute/>,
    Compare: <Icon.Compare/>,
    CompareArrows: <Icon.CompareArrows/>,
    CompassCalibration: <Icon.CompassCalibration/>,
    Computer: <Icon.Computer/>,
    ConfirmationNumber: <Icon.ConfirmationNumber/>,
    ContactMail: <Icon.ContactMail/>,
    ContactPhone: <Icon.ContactPhone/>,
    Contacts: <Icon.Contacts/>,
    ContactSupport: <Icon.ContactSupport/>,
    ControlCamera: <Icon.ControlCamera/>,
    ControlPoint: <Icon.ControlPoint/>,
    ControlPointDuplicate: <Icon.ControlPointDuplicate/>,
    Copyright: <Icon.Copyright/>,
    Create: <Icon.Create/>,
    CreateNewFolder: <Icon.CreateNewFolder/>,
    CreditCard: <Icon.CreditCard/>,
    Crop: <Icon.Crop/>,
    Crop169: <Icon.Crop169/>,
    Crop32: <Icon.Crop32/>,
    Crop54: <Icon.Crop54/>,
    Crop75: <Icon.Crop75/>,
    CropDin: <Icon.CropDin/>,
    CropFree: <Icon.CropFree/>,
    CropLandscape: <Icon.CropLandscape/>,
    CropOriginal: <Icon.CropOriginal/>,
    CropPortrait: <Icon.CropPortrait/>,
    CropRotate: <Icon.CropRotate/>,
    CropSquare: <Icon.CropSquare/>,
    Dashboard: <Icon.Dashboard/>,
    DataUsage: <Icon.DataUsage/>,
    DateRange: <Icon.DateRange/>,
    Dehaze: <Icon.Dehaze/>,
    Delete: <Icon.Delete/>,
    DeleteForever: <Icon.DeleteForever/>,
    DeleteOutline: <Icon.DeleteOutline/>,
    DeleteSweep: <Icon.DeleteSweep/>,
    DepartureBoard: <Icon.DepartureBoard/>,
    Description: <Icon.Description/>,
    DesktopAccessDisabled: <Icon.DesktopAccessDisabled/>,
    DesktopMac: <Icon.DesktopMac/>,
    DesktopWindows: <Icon.DesktopWindows/>,
    Details: <Icon.Details/>,
    DeveloperBoard: <Icon.DeveloperBoard/>,
    DeveloperMode: <Icon.DeveloperMode/>,
    DeviceHub: <Icon.DeviceHub/>,
    Devices: <Icon.Devices/>,
    DevicesOther: <Icon.DevicesOther/>,
    DeviceUnknown: <Icon.DeviceUnknown/>,
    DialerSip: <Icon.DialerSip/>,
    Dialpad: <Icon.Dialpad/>,
    Directions: <Icon.Directions/>,
    DirectionsBike: <Icon.DirectionsBike/>,
    DirectionsBoat: <Icon.DirectionsBoat/>,
    DirectionsBus: <Icon.DirectionsBus/>,
    DirectionsCar: <Icon.DirectionsCar/>,
    DirectionsRailway: <Icon.DirectionsRailway/>,
    DirectionsRun: <Icon.DirectionsRun/>,
    DirectionsSubway: <Icon.DirectionsSubway/>,
    DirectionsTransit: <Icon.DirectionsTransit/>,
    DirectionsWalk: <Icon.DirectionsWalk/>,
    DiscFull: <Icon.DiscFull/>,
    Dns: <Icon.Dns/>,
    Dock: <Icon.Dock/>,
    Domain: <Icon.Domain/>,
    DomainDisabled: <Icon.DomainDisabled/>,
    Done: <Icon.Done/>,
    DoneAll: <Icon.DoneAll/>,
    DoneOutline: <Icon.DoneOutline/>,
    DonutLarge: <Icon.DonutLarge/>,
    DonutSmall: <Icon.DonutSmall/>,
    Drafts: <Icon.Drafts/>,
    DragHandle: <Icon.DragHandle/>,
    DragIndicator: <Icon.DragIndicator/>,
    DriveEta: <Icon.DriveEta/>,
    Duo: <Icon.Duo/>,
    Dvr: <Icon.Dvr/>,
    Edit: <Icon.Edit/>,
    EditAttributes: <Icon.EditAttributes/>,
    EditLocation: <Icon.EditLocation/>,
    Eject: <Icon.Eject/>,
    Email: <Icon.Email/>,
    EnhancedEncryption: <Icon.EnhancedEncryption/>,
    Equalizer: <Icon.Equalizer/>,
    Error: <Icon.Error/>,
    ErrorOutline: <Icon.ErrorOutline/>,
    EuroSymbol: <Icon.EuroSymbol/>,
    Event: <Icon.Event/>,
    EventAvailable: <Icon.EventAvailable/>,
    EventBusy: <Icon.EventBusy/>,
    EventNote: <Icon.EventNote/>,
    EventSeat: <Icon.EventSeat/>,
    EvStation: <Icon.EvStation/>,
    ExitToApp: <Icon.ExitToApp/>,
    ExpandLess: <Icon.ExpandLess/>,
    ExpandMore: <Icon.ExpandMore/>,
    Explicit: <Icon.Explicit/>,
    Explore: <Icon.Explore/>,
    ExploreOff: <Icon.ExploreOff/>,
    Exposure: <Icon.Exposure/>,
    Extension: <Icon.Extension/>,
    Face: <Icon.Face/>,
    Fastfood: <Icon.Fastfood/>,
    FastForward: <Icon.FastForward/>,
    FastRewind: <Icon.FastRewind/>,
    Favorite: <Icon.Favorite/>,
    FavoriteBorder: <Icon.FavoriteBorder/>,
    FeaturedPlayList: <Icon.FeaturedPlayList/>,
    FeaturedVideo: <Icon.FeaturedVideo/>,
    Feedback: <Icon.Feedback/>,
    FiberDvr: <Icon.FiberDvr/>,
    FiberManualRecord: <Icon.FiberManualRecord/>,
    FiberNew: <Icon.FiberNew/>,
    FiberPin: <Icon.FiberPin/>,
    FiberSmartRecord: <Icon.FiberSmartRecord/>,
    FileCopy: <Icon.FileCopy/>,
    Filter: <Icon.Filter/>,
    Filter1: <Icon.Filter1/>,
    Filter2: <Icon.Filter2/>,
    Filter3: <Icon.Filter3/>,
    Filter4: <Icon.Filter4/>,
    Filter5: <Icon.Filter5/>,
    Filter6: <Icon.Filter6/>,
    Filter7: <Icon.Filter7/>,
    Filter8: <Icon.Filter8/>,
    Filter9: <Icon.Filter9/>,
    Filter9Plus: <Icon.Filter9Plus/>,
    FilterBAndW: <Icon.FilterBAndW/>,
    FilterCenterFocus: <Icon.FilterCenterFocus/>,
    FilterDrama: <Icon.FilterDrama/>,
    FilterFrames: <Icon.FilterFrames/>,
    FilterHdr: <Icon.FilterHdr/>,
    FilterList: <Icon.FilterList/>,
    FilterNone: <Icon.FilterNone/>,
    FilterTiltShift: <Icon.FilterTiltShift/>,
    FilterVintage: <Icon.FilterVintage/>,
    FindInPage: <Icon.FindInPage/>,
    FindReplace: <Icon.FindReplace/>,
    Fingerprint: <Icon.Fingerprint/>,
    FirstPage: <Icon.FirstPage/>,
    FitnessCenter: <Icon.FitnessCenter/>,
    Flag: <Icon.Flag/>,
    Flare: <Icon.Flare/>,
    FlashAuto: <Icon.FlashAuto/>,
    FlashOff: <Icon.FlashOff/>,
    FlashOn: <Icon.FlashOn/>,
    Flight: <Icon.Flight/>,
    FlightLand: <Icon.FlightLand/>,
    FlightTakeoff: <Icon.FlightTakeoff/>,
    Flip: <Icon.Flip/>,
    FlipToBack: <Icon.FlipToBack/>,
    FlipToFront: <Icon.FlipToFront/>,
    Folder: <Icon.Folder/>,
    FolderOpen: <Icon.FolderOpen/>,
    FolderShared: <Icon.FolderShared/>,
    FolderSpecial: <Icon.FolderSpecial/>,
    FontDownload: <Icon.FontDownload/>,
    FormatAlignCenter: <Icon.FormatAlignCenter/>,
    FormatAlignJustify: <Icon.FormatAlignJustify/>,
    FormatAlignLeft: <Icon.FormatAlignLeft/>,
    FormatAlignRight: <Icon.FormatAlignRight/>,
    FormatBold: <Icon.FormatBold/>,
    FormatClear: <Icon.FormatClear/>,
    FormatColorFill: <Icon.FormatColorFill/>,
    FormatColorReset: <Icon.FormatColorReset/>,
    FormatColorText: <Icon.FormatColorText/>,
    FormatIndentDecrease: <Icon.FormatIndentDecrease/>,
    FormatIndentIncrease: <Icon.FormatIndentIncrease/>,
    FormatItalic: <Icon.FormatItalic/>,
    FormatLineSpacing: <Icon.FormatLineSpacing/>,
    FormatListBulleted: <Icon.FormatListBulleted/>,
    FormatListNumbered: <Icon.FormatListNumbered/>,
    FormatListNumberedRtl: <Icon.FormatListNumberedRtl/>,
    FormatPaint: <Icon.FormatPaint/>,
    FormatQuote: <Icon.FormatQuote/>,
    FormatShapes: <Icon.FormatShapes/>,
    FormatSize: <Icon.FormatSize/>,
    FormatStrikethrough: <Icon.FormatStrikethrough/>,
    FormatTextdirectionLToR: <Icon.FormatTextdirectionLToR/>,
    FormatTextdirectionRToL: <Icon.FormatTextdirectionRToL/>,
    FormatUnderlined: <Icon.FormatUnderlined/>,
    Forum: <Icon.Forum/>,
    Forward: <Icon.Forward/>,
    Forward10: <Icon.Forward10/>,
    Forward30: <Icon.Forward30/>,
    Forward5: <Icon.Forward5/>,
    FourK: <Icon.FourK/>,
    FreeBreakfast: <Icon.FreeBreakfast/>,
    Fullscreen: <Icon.Fullscreen/>,
    FullscreenExit: <Icon.FullscreenExit/>,
    Functions: <Icon.Functions/>,
    Gamepad: <Icon.Gamepad/>,
    Games: <Icon.Games/>,
    Gavel: <Icon.Gavel/>,
    Gesture: <Icon.Gesture/>,
    GetApp: <Icon.GetApp/>,
    Gif: <Icon.Gif/>,
    GolfCourse: <Icon.GolfCourse/>,
    GpsFixed: <Icon.GpsFixed/>,
    GpsNotFixed: <Icon.GpsNotFixed/>,
    GpsOff: <Icon.GpsOff/>,
    Grade: <Icon.Grade/>,
    Gradient: <Icon.Gradient/>,
    Grain: <Icon.Grain/>,
    GraphicEq: <Icon.GraphicEq/>,
    GridOff: <Icon.GridOff/>,
    GridOn: <Icon.GridOn/>,
    Group: <Icon.Group/>,
    GroupAdd: <Icon.GroupAdd/>,
    GroupWork: <Icon.GroupWork/>,
    GTranslate: <Icon.GTranslate/>,
    Hd: <Icon.Hd/>,
    HdrOff: <Icon.HdrOff/>,
    HdrOn: <Icon.HdrOn/>,
    HdrStrong: <Icon.HdrStrong/>,
    HdrWeak: <Icon.HdrWeak/>,
    Headset: <Icon.Headset/>,
    HeadsetMic: <Icon.HeadsetMic/>,
    Healing: <Icon.Healing/>,
    Hearing: <Icon.Hearing/>,
    Help: <Icon.Help/>,
    HelpOutline: <Icon.HelpOutline/>,
    Highlight: <Icon.Highlight/>,
    HighlightOff: <Icon.HighlightOff/>,
    HighQuality: <Icon.HighQuality/>,
    History: <Icon.History/>,
    Home: <Icon.Home/>,
    HorizontalSplit: <Icon.HorizontalSplit/>,
    Hotel: <Icon.Hotel/>,
    HotTub: <Icon.HotTub/>,
    HourglassEmpty: <Icon.HourglassEmpty/>,
    HourglassFull: <Icon.HourglassFull/>,
    HowToReg: <Icon.HowToReg/>,
    HowToVote: <Icon.HowToVote/>,
    Http: <Icon.Http/>,
    Https: <Icon.Https/>,
    Image: <Icon.Image/>,
    ImageAspectRatio: <Icon.ImageAspectRatio/>,
    ImageSearch: <Icon.ImageSearch/>,
    ImportantDevices: <Icon.ImportantDevices/>,
    ImportContacts: <Icon.ImportContacts/>,
    ImportExport: <Icon.ImportExport/>,
    Inbox: <Icon.Inbox/>,
    IndeterminateCheckBox: <Icon.IndeterminateCheckBox/>,
    Info: <Icon.Info/>,
    Input: <Icon.Input/>,
    InsertChart: <Icon.InsertChart/>,
    InsertComment: <Icon.InsertComment/>,
    InsertDriveFile: <Icon.InsertDriveFile/>,
    InsertEmoticon: <Icon.InsertEmoticon/>,
    InsertInvitation: <Icon.InsertInvitation/>,
    InsertLink: <Icon.InsertLink/>,
    InsertPhoto: <Icon.InsertPhoto/>,
    InvertColors: <Icon.InvertColors/>,
    InvertColorsOff: <Icon.InvertColorsOff/>,
    Iso: <Icon.Iso/>,
    Keyboard: <Icon.Keyboard/>,
    KeyboardArrowDown: <Icon.KeyboardArrowDown/>,
    KeyboardArrowLeft: <Icon.KeyboardArrowLeft/>,
    KeyboardArrowRight: <Icon.KeyboardArrowRight/>,
    KeyboardArrowUp: <Icon.KeyboardArrowUp/>,
    KeyboardBackspace: <Icon.KeyboardBackspace/>,
    KeyboardCapslock: <Icon.KeyboardCapslock/>,
    KeyboardHide: <Icon.KeyboardHide/>,
    KeyboardReturn: <Icon.KeyboardReturn/>,
    KeyboardTab: <Icon.KeyboardTab/>,
    KeyboardVoice: <Icon.KeyboardVoice/>,
    Kitchen: <Icon.Kitchen/>,
    Label: <Icon.Label/>,
    LabelImportant: <Icon.LabelImportant/>,
    LabelOff: <Icon.LabelOff/>,
    Landscape: <Icon.Landscape/>,
    Language: <Icon.Language/>,
    Laptop: <Icon.Laptop/>,
    LaptopChromebook: <Icon.LaptopChromebook/>,
    LaptopMac: <Icon.LaptopMac/>,
    LaptopWindows: <Icon.LaptopWindows/>,
    LastPage: <Icon.LastPage/>,
    Launch: <Icon.Launch/>,
    Layers: <Icon.Layers/>,
    LayersClear: <Icon.LayersClear/>,
    LeakAdd: <Icon.LeakAdd/>,
    LeakRemove: <Icon.LeakRemove/>,
    Lens: <Icon.Lens/>,
    LibraryAdd: <Icon.LibraryAdd/>,
    LibraryBooks: <Icon.LibraryBooks/>,
    LibraryMusic: <Icon.LibraryMusic/>,
    LinearScale: <Icon.LinearScale/>,
    LineStyle: <Icon.LineStyle/>,
    LineWeight: <Icon.LineWeight/>,
    Link: <Icon.Link/>,
    LinkedCamera: <Icon.LinkedCamera/>,
    LinkOff: <Icon.LinkOff/>,
    List: <Icon.List/>,
    ListAlt: <Icon.ListAlt/>,
    LiveHelp: <Icon.LiveHelp/>,
    LiveTv: <Icon.LiveTv/>,
    LocalActivity: <Icon.LocalActivity/>,
    LocalAirport: <Icon.LocalAirport/>,
    LocalAtm: <Icon.LocalAtm/>,
    LocalBar: <Icon.LocalBar/>,
    LocalCafe: <Icon.LocalCafe/>,
    LocalCarWash: <Icon.LocalCarWash/>,
    LocalConvenienceStore: <Icon.LocalConvenienceStore/>,
    LocalDining: <Icon.LocalDining/>,
    LocalDrink: <Icon.LocalDrink/>,
    LocalFlorist: <Icon.LocalFlorist/>,
    LocalGasStation: <Icon.LocalGasStation/>,
    LocalGroceryStore: <Icon.LocalGroceryStore/>,
    LocalHospital: <Icon.LocalHospital/>,
    LocalHotel: <Icon.LocalHotel/>,
    LocalLaundryService: <Icon.LocalLaundryService/>,
    LocalLibrary: <Icon.LocalLibrary/>,
    LocalMall: <Icon.LocalMall/>,
    LocalMovies: <Icon.LocalMovies/>,
    LocalOffer: <Icon.LocalOffer/>,
    LocalParking: <Icon.LocalParking/>,
    LocalPharmacy: <Icon.LocalPharmacy/>,
    LocalPhone: <Icon.LocalPhone/>,
    LocalPizza: <Icon.LocalPizza/>,
    LocalPlay: <Icon.LocalPlay/>,
    LocalPostOffice: <Icon.LocalPostOffice/>,
    LocalPrintshop: <Icon.LocalPrintshop/>,
    LocalSee: <Icon.LocalSee/>,
    LocalShipping: <Icon.LocalShipping/>,
    LocalTaxi: <Icon.LocalTaxi/>,
    LocationCity: <Icon.LocationCity/>,
    LocationDisabled: <Icon.LocationDisabled/>,
    LocationOff: <Icon.LocationOff/>,
    LocationOn: <Icon.LocationOn/>,
    LocationSearching: <Icon.LocationSearching/>,
    Lock: <Icon.Lock/>,
    LockOpen: <Icon.LockOpen/>,
    Looks: <Icon.Looks/>,
    Looks3: <Icon.Looks3/>,
    Looks4: <Icon.Looks4/>,
    Looks5: <Icon.Looks5/>,
    Looks6: <Icon.Looks6/>,
    LooksOne: <Icon.LooksOne/>,
    LooksTwo: <Icon.LooksTwo/>,
    Loop: <Icon.Loop/>,
    Loupe: <Icon.Loupe/>,
    LowPriority: <Icon.LowPriority/>,
    Loyalty: <Icon.Loyalty/>,
    Mail: <Icon.Mail/>,
    MailOutline: <Icon.MailOutline/>,
    Map: <Icon.Map/>,
    Markunread: <Icon.Markunread/>,
    MarkunreadMailbox: <Icon.MarkunreadMailbox/>,
    Maximize: <Icon.Maximize/>,
    MeetingRoom: <Icon.MeetingRoom/>,
    Memory: <Icon.Memory/>,
    Menu: <Icon.Menu/>,
    MergeType: <Icon.MergeType/>,
    Message: <Icon.Message/>,
    Mic: <Icon.Mic/>,
    MicNone: <Icon.MicNone/>,
    MicOff: <Icon.MicOff/>,
    Minimize: <Icon.Minimize/>,
    MissedVideoCall: <Icon.MissedVideoCall/>,
    Mms: <Icon.Mms/>,
    MobileFriendly: <Icon.MobileFriendly/>,
    MobileOff: <Icon.MobileOff/>,
    MobileScreenShare: <Icon.MobileScreenShare/>,
    ModeComment: <Icon.ModeComment/>,
    MonetizationOn: <Icon.MonetizationOn/>,
    Money: <Icon.Money/>,
    MoneyOff: <Icon.MoneyOff/>,
    MonochromePhotos: <Icon.MonochromePhotos/>,
    Mood: <Icon.Mood/>,
    MoodBad: <Icon.MoodBad/>,
    More: <Icon.More/>,
    MoreHoriz: <Icon.MoreHoriz/>,
    MoreVert: <Icon.MoreVert/>,
    Mouse: <Icon.Mouse/>,
    MoveToInbox: <Icon.MoveToInbox/>,
    Movie: <Icon.Movie/>,
    MovieCreation: <Icon.MovieCreation/>,
    MovieFilter: <Icon.MovieFilter/>,
    MultilineChart: <Icon.MultilineChart/>,
    MusicNote: <Icon.MusicNote/>,
    MusicOff: <Icon.MusicOff/>,
    MusicVideo: <Icon.MusicVideo/>,
    MyLocation: <Icon.MyLocation/>,
    Nature: <Icon.Nature/>,
    NaturePeople: <Icon.NaturePeople/>,
    NavigateBefore: <Icon.NavigateBefore/>,
    NavigateNext: <Icon.NavigateNext/>,
    Navigation: <Icon.Navigation/>,
    NearMe: <Icon.NearMe/>,
    NetworkCell: <Icon.NetworkCell/>,
    NetworkCheck: <Icon.NetworkCheck/>,
    NetworkLocked: <Icon.NetworkLocked/>,
    NetworkWifi: <Icon.NetworkWifi/>,
    NewReleases: <Icon.NewReleases/>,
    NextWeek: <Icon.NextWeek/>,
    Nfc: <Icon.Nfc/>,
    NoEncryption: <Icon.NoEncryption/>,
    NoMeetingRoom: <Icon.NoMeetingRoom/>,
    NoSim: <Icon.NoSim/>,
    Note: <Icon.Note/>,
    NoteAdd: <Icon.NoteAdd/>,
    Notes: <Icon.Notes/>,
    NotificationImportant: <Icon.NotificationImportant/>,
    Notifications: <Icon.Notifications/>,
    NotificationsActive: <Icon.NotificationsActive/>,
    NotificationsNone: <Icon.NotificationsNone/>,
    NotificationsOff: <Icon.NotificationsOff/>,
    NotificationsPaused: <Icon.NotificationsPaused/>,
    NotInterested: <Icon.NotInterested/>,
    NotListedLocation: <Icon.NotListedLocation/>,
    OfflineBolt: <Icon.OfflineBolt/>,
    OfflinePin: <Icon.OfflinePin/>,
    OndemandVideo: <Icon.OndemandVideo/>,
    Opacity: <Icon.Opacity/>,
    OpenInBrowser: <Icon.OpenInBrowser/>,
    OpenInNew: <Icon.OpenInNew/>,
    OpenWith: <Icon.OpenWith/>,
    OutlinedFlag: <Icon.OutlinedFlag/>,
    Pages: <Icon.Pages/>,
    Pageview: <Icon.Pageview/>,
    Palette: <Icon.Palette/>,
    Panorama: <Icon.Panorama/>,
    PanoramaFishEye: <Icon.PanoramaFishEye/>,
    PanoramaHorizontal: <Icon.PanoramaHorizontal/>,
    PanoramaVertical: <Icon.PanoramaVertical/>,
    PanoramaWideAngle: <Icon.PanoramaWideAngle/>,
    PanTool: <Icon.PanTool/>,
    PartyMode: <Icon.PartyMode/>,
    Pause: <Icon.Pause/>,
    PauseCircleFilled: <Icon.PauseCircleFilled/>,
    PauseCircleOutline: <Icon.PauseCircleOutline/>,
    PausePresentation: <Icon.PausePresentation/>,
    Payment: <Icon.Payment/>,
    People: <Icon.People/>,
    PeopleOutline: <Icon.PeopleOutline/>,
    PermCameraMic: <Icon.PermCameraMic/>,
    PermContactCalendar: <Icon.PermContactCalendar/>,
    PermDataSetting: <Icon.PermDataSetting/>,
    PermDeviceInformation: <Icon.PermDeviceInformation/>,
    PermIdentity: <Icon.PermIdentity/>,
    PermMedia: <Icon.PermMedia/>,
    PermPhoneMsg: <Icon.PermPhoneMsg/>,
    PermScanWifi: <Icon.PermScanWifi/>,
    Person: <Icon.Person/>,
    PersonAdd: <Icon.PersonAdd/>,
    PersonAddDisabled: <Icon.PersonAddDisabled/>,
    PersonalVideo: <Icon.PersonalVideo/>,
    PersonOutline: <Icon.PersonOutline/>,
    PersonPin: <Icon.PersonPin/>,
    PersonPinCircle: <Icon.PersonPinCircle/>,
    Pets: <Icon.Pets/>,
    Phone: <Icon.Phone/>,
    PhoneAndroid: <Icon.PhoneAndroid/>,
    PhoneBluetoothSpeaker: <Icon.PhoneBluetoothSpeaker/>,
    PhoneCallback: <Icon.PhoneCallback/>,
    PhoneForwarded: <Icon.PhoneForwarded/>,
    PhoneInTalk: <Icon.PhoneInTalk/>,
    PhoneIphone: <Icon.PhoneIphone/>,
    Phonelink: <Icon.Phonelink/>,
    PhonelinkErase: <Icon.PhonelinkErase/>,
    PhonelinkLock: <Icon.PhonelinkLock/>,
    PhonelinkOff: <Icon.PhonelinkOff/>,
    PhonelinkRing: <Icon.PhonelinkRing/>,
    PhonelinkSetup: <Icon.PhonelinkSetup/>,
    PhoneLocked: <Icon.PhoneLocked/>,
    PhoneMissed: <Icon.PhoneMissed/>,
    PhonePaused: <Icon.PhonePaused/>,
    Photo: <Icon.Photo/>,
    PhotoAlbum: <Icon.PhotoAlbum/>,
    PhotoCamera: <Icon.PhotoCamera/>,
    PhotoFilter: <Icon.PhotoFilter/>,
    PhotoLibrary: <Icon.PhotoLibrary/>,
    PhotoSizeSelectActual: <Icon.PhotoSizeSelectActual/>,
    PhotoSizeSelectLarge: <Icon.PhotoSizeSelectLarge/>,
    PhotoSizeSelectSmall: <Icon.PhotoSizeSelectSmall/>,
    PictureAsPdf: <Icon.PictureAsPdf/>,
    PictureInPicture: <Icon.PictureInPicture/>,
    PictureInPictureAlt: <Icon.PictureInPictureAlt/>,
    PieChart: <Icon.PieChart/>,
    PinDrop: <Icon.PinDrop/>,
    Place: <Icon.Place/>,
    PlayArrow: <Icon.PlayArrow/>,
    PlayCircleFilled: <Icon.PlayCircleFilled/>,
    PlayCircleFilledWhite: <Icon.PlayCircleFilledWhite/>,
    PlayCircleOutline: <Icon.PlayCircleOutline/>,
    PlayForWork: <Icon.PlayForWork/>,
    PlaylistAdd: <Icon.PlaylistAdd/>,
    PlaylistAddCheck: <Icon.PlaylistAddCheck/>,
    PlaylistPlay: <Icon.PlaylistPlay/>,
    PlusOne: <Icon.PlusOne/>,
    Poll: <Icon.Poll/>,
    Pool: <Icon.Pool/>,
    PortableWifiOff: <Icon.PortableWifiOff/>,
    Portrait: <Icon.Portrait/>,
    Power: <Icon.Power/>,
    PowerInput: <Icon.PowerInput/>,
    PowerOff: <Icon.PowerOff/>,
    PowerSettingsNew: <Icon.PowerSettingsNew/>,
    PregnantWoman: <Icon.PregnantWoman/>,
    PresentToAll: <Icon.PresentToAll/>,
    Print: <Icon.Print/>,
    PrintDisabled: <Icon.PrintDisabled/>,
    PriorityHigh: <Icon.PriorityHigh/>,
    Public: <Icon.Public/>,
    Publish: <Icon.Publish/>,
    QueryBuilder: <Icon.QueryBuilder/>,
    QuestionAnswer: <Icon.QuestionAnswer/>,
    Queue: <Icon.Queue/>,
    QueueMusic: <Icon.QueueMusic/>,
    QueuePlayNext: <Icon.QueuePlayNext/>,
    Radio: <Icon.Radio/>,
    RadioButtonChecked: <Icon.RadioButtonChecked/>,
    RadioButtonUnchecked: <Icon.RadioButtonUnchecked/>,
    RateReview: <Icon.RateReview/>,
    Receipt: <Icon.Receipt/>,
    RecentActors: <Icon.RecentActors/>,
    RecordVoiceOver: <Icon.RecordVoiceOver/>,
    Redeem: <Icon.Redeem/>,
    Redo: <Icon.Redo/>,
    Refresh: <Icon.Refresh/>,
    Remove: <Icon.Remove/>,
    RemoveCircle: <Icon.RemoveCircle/>,
    RemoveCircleOutline: <Icon.RemoveCircleOutline/>,
    RemoveFromQueue: <Icon.RemoveFromQueue/>,
    RemoveRedEye: <Icon.RemoveRedEye/>,
    RemoveShoppingCart: <Icon.RemoveShoppingCart/>,
    Reorder: <Icon.Reorder/>,
    Repeat: <Icon.Repeat/>,
    RepeatOne: <Icon.RepeatOne/>,
    Replay: <Icon.Replay/>,
    Replay10: <Icon.Replay10/>,
    Replay30: <Icon.Replay30/>,
    Replay5: <Icon.Replay5/>,
    Reply: <Icon.Reply/>,
    ReplyAll: <Icon.ReplyAll/>,
    Report: <Icon.Report/>,
    ReportOff: <Icon.ReportOff/>,
    ReportProblem: <Icon.ReportProblem/>,
    Restaurant: <Icon.Restaurant/>,
    RestaurantMenu: <Icon.RestaurantMenu/>,
    Restore: <Icon.Restore/>,
    RestoreFromTrash: <Icon.RestoreFromTrash/>,
    RestorePage: <Icon.RestorePage/>,
    RingVolume: <Icon.RingVolume/>,
    Room: <Icon.Room/>,
    RoomService: <Icon.RoomService/>,
    Rotate90DegreesCcw: <Icon.Rotate90DegreesCcw/>,
    RotateLeft: <Icon.RotateLeft/>,
    RotateRight: <Icon.RotateRight/>,
    RoundedCorner: <Icon.RoundedCorner/>,
    Router: <Icon.Router/>,
    Rowing: <Icon.Rowing/>,
    RssFeed: <Icon.RssFeed/>,
    RvHookup: <Icon.RvHookup/>,
    Satellite: <Icon.Satellite/>,
    Save: <Icon.Save/>,
    SaveAlt: <Icon.SaveAlt/>,
    Scanner: <Icon.Scanner/>,
    ScatterPlot: <Icon.ScatterPlot/>,
    Schedule: <Icon.Schedule/>,
    School: <Icon.School/>,
    Score: <Icon.Score/>,
    ScreenLockLandscape: <Icon.ScreenLockLandscape/>,
    ScreenLockPortrait: <Icon.ScreenLockPortrait/>,
    ScreenLockRotation: <Icon.ScreenLockRotation/>,
    ScreenRotation: <Icon.ScreenRotation/>,
    ScreenShare: <Icon.ScreenShare/>,
    SdCard: <Icon.SdCard/>,
    SdStorage: <Icon.SdStorage/>,
    Search: <Icon.Search/>,
    Security: <Icon.Security/>,
    SelectAll: <Icon.SelectAll/>,
    Send: <Icon.Send/>,
    SentimentDissatisfied: <Icon.SentimentDissatisfied/>,
    SentimentSatisfied: <Icon.SentimentSatisfied/>,
    SentimentSatisfiedAlt: <Icon.SentimentSatisfiedAlt/>,
    SentimentVeryDissatisfied: <Icon.SentimentVeryDissatisfied/>,
    SentimentVerySatisfied: <Icon.SentimentVerySatisfied/>,
    Settings: <Icon.Settings/>,
    SettingsApplications: <Icon.SettingsApplications/>,
    SettingsBackupRestore: <Icon.SettingsBackupRestore/>,
    SettingsBluetooth: <Icon.SettingsBluetooth/>,
    SettingsBrightness: <Icon.SettingsBrightness/>,
    SettingsCell: <Icon.SettingsCell/>,
    SettingsEthernet: <Icon.SettingsEthernet/>,
    SettingsInputAntenna: <Icon.SettingsInputAntenna/>,
    SettingsInputComponent: <Icon.SettingsInputComponent/>,
    SettingsInputComposite: <Icon.SettingsInputComposite/>,
    SettingsInputHdmi: <Icon.SettingsInputHdmi/>,
    SettingsInputSvideo: <Icon.SettingsInputSvideo/>,
    SettingsOverscan: <Icon.SettingsOverscan/>,
    SettingsPhone: <Icon.SettingsPhone/>,
    SettingsPower: <Icon.SettingsPower/>,
    SettingsRemote: <Icon.SettingsRemote/>,
    SettingsSystemDaydream: <Icon.SettingsSystemDaydream/>,
    SettingsVoice: <Icon.SettingsVoice/>,
    Share: <Icon.Share/>,
    Shop: <Icon.Shop/>,
    ShoppingBasket: <Icon.ShoppingBasket/>,
    ShoppingCart: <Icon.ShoppingCart/>,
    ShopTwo: <Icon.ShopTwo/>,
    ShortText: <Icon.ShortText/>,
    ShowChart: <Icon.ShowChart/>,
    Shuffle: <Icon.Shuffle/>,
    ShutterSpeed: <Icon.ShutterSpeed/>,
    SignalCellular0Bar: <Icon.SignalCellular0Bar/>,
    SignalCellular1Bar: <Icon.SignalCellular1Bar/>,
    SignalCellular2Bar: <Icon.SignalCellular2Bar/>,
    SignalCellular3Bar: <Icon.SignalCellular3Bar/>,
    SignalCellular4Bar: <Icon.SignalCellular4Bar/>,
    SignalCellularAlt: <Icon.SignalCellularAlt/>,
    SignalCellularConnectedNoInternet0Bar: (
        <Icon.SignalCellularConnectedNoInternet0Bar/>
    ),
    SignalCellularConnectedNoInternet1Bar: (
        <Icon.SignalCellularConnectedNoInternet1Bar/>
    ),
    SignalCellularConnectedNoInternet2Bar: (
        <Icon.SignalCellularConnectedNoInternet2Bar/>
    ),
    SignalCellularConnectedNoInternet3Bar: (
        <Icon.SignalCellularConnectedNoInternet3Bar/>
    ),
    SignalCellularConnectedNoInternet4Bar: (
        <Icon.SignalCellularConnectedNoInternet4Bar/>
    ),
    SignalCellularNoSim: <Icon.SignalCellularNoSim/>,
    SignalCellularNull: <Icon.SignalCellularNull/>,
    SignalCellularOff: <Icon.SignalCellularOff/>,
    SignalWifi0Bar: <Icon.SignalWifi0Bar/>,
    SignalWifi1Bar: <Icon.SignalWifi1Bar/>,
    SignalWifi1BarLock: <Icon.SignalWifi1BarLock/>,
    SignalWifi2Bar: <Icon.SignalWifi2Bar/>,
    SignalWifi2BarLock: <Icon.SignalWifi2BarLock/>,
    SignalWifi3Bar: <Icon.SignalWifi3Bar/>,
    SignalWifi3BarLock: <Icon.SignalWifi3BarLock/>,
    SignalWifi4Bar: <Icon.SignalWifi4Bar/>,
    SignalWifi4BarLock: <Icon.SignalWifi4BarLock/>,
    SignalWifiOff: <Icon.SignalWifiOff/>,
    SimCard: <Icon.SimCard/>,
    SkipNext: <Icon.SkipNext/>,
    SkipPrevious: <Icon.SkipPrevious/>,
    Slideshow: <Icon.Slideshow/>,
    SlowMotionVideo: <Icon.SlowMotionVideo/>,
    Smartphone: <Icon.Smartphone/>,
    SmokeFree: <Icon.SmokeFree/>,
    SmokingRooms: <Icon.SmokingRooms/>,
    Sms: <Icon.Sms/>,
    SmsFailed: <Icon.SmsFailed/>,
    Snooze: <Icon.Snooze/>,
    Sort: <Icon.Sort/>,
    SortByAlpha: <Icon.SortByAlpha/>,
    Spa: <Icon.Spa/>,
    SpaceBar: <Icon.SpaceBar/>,
    Speaker: <Icon.Speaker/>,
    SpeakerGroup: <Icon.SpeakerGroup/>,
    SpeakerNotes: <Icon.SpeakerNotes/>,
    SpeakerNotesOff: <Icon.SpeakerNotesOff/>,
    SpeakerPhone: <Icon.SpeakerPhone/>,
    Spellcheck: <Icon.Spellcheck/>,
    Star: <Icon.Star/>,
    StarBorder: <Icon.StarBorder/>,
    StarHalf: <Icon.StarHalf/>,
    StarRate: <Icon.StarRate/>,
    Stars: <Icon.Stars/>,
    StayCurrentLandscape: <Icon.StayCurrentLandscape/>,
    StayCurrentPortrait: <Icon.StayCurrentPortrait/>,
    StayPrimaryLandscape: <Icon.StayPrimaryLandscape/>,
    StayPrimaryPortrait: <Icon.StayPrimaryPortrait/>,
    Stop: <Icon.Stop/>,
    StopScreenShare: <Icon.StopScreenShare/>,
    Storage: <Icon.Storage/>,
    Store: <Icon.Store/>,
    StoreMallDirectory: <Icon.StoreMallDirectory/>,
    Straighten: <Icon.Straighten/>,
    Streetview: <Icon.Streetview/>,
    StrikethroughS: <Icon.StrikethroughS/>,
    Style: <Icon.Style/>,
    SubdirectoryArrowLeft: <Icon.SubdirectoryArrowLeft/>,
    SubdirectoryArrowRight: <Icon.SubdirectoryArrowRight/>,
    Subject: <Icon.Subject/>,
    Subscriptions: <Icon.Subscriptions/>,
    Subtitles: <Icon.Subtitles/>,
    Subway: <Icon.Subway/>,
    SupervisedUserCircle: <Icon.SupervisedUserCircle/>,
    SupervisorAccount: <Icon.SupervisorAccount/>,
    SurroundSound: <Icon.SurroundSound/>,
    SwapCalls: <Icon.SwapCalls/>,
    SwapHoriz: <Icon.SwapHoriz/>,
    SwapHorizontalCircle: <Icon.SwapHorizontalCircle/>,
    SwapVert: <Icon.SwapVert/>,
    SwapVerticalCircle: <Icon.SwapVerticalCircle/>,
    SwitchCamera: <Icon.SwitchCamera/>,
    SwitchVideo: <Icon.SwitchVideo/>,
    Sync: <Icon.Sync/>,
    SyncDisabled: <Icon.SyncDisabled/>,
    SyncProblem: <Icon.SyncProblem/>,
    SystemUpdate: <Icon.SystemUpdate/>,
    Tab: <Icon.Tab/>,
    TableChart: <Icon.TableChart/>,
    Tablet: <Icon.Tablet/>,
    TabletAndroid: <Icon.TabletAndroid/>,
    TabletMac: <Icon.TabletMac/>,
    TabUnselected: <Icon.TabUnselected/>,
    TagFaces: <Icon.TagFaces/>,
    TapAndPlay: <Icon.TapAndPlay/>,
    Terrain: <Icon.Terrain/>,
    TextFields: <Icon.TextFields/>,
    TextFormat: <Icon.TextFormat/>,
    TextRotateUp: <Icon.TextRotateUp/>,
    TextRotateVertical: <Icon.TextRotateVertical/>,
    TextRotationDown: <Icon.TextRotationDown/>,
    TextRotationNone: <Icon.TextRotationNone/>,
    Textsms: <Icon.Textsms/>,
    Texture: <Icon.Texture/>,
    Theaters: <Icon.Theaters/>,
    ThreeDRotation: <Icon.ThreeDRotation/>,
    ThreeSixty: <Icon.ThreeSixty/>,
    ThumbDown: <Icon.ThumbDown/>,
    ThumbDownAlt: <Icon.ThumbDownAlt/>,
    ThumbsUpDown: <Icon.ThumbsUpDown/>,
    ThumbUp: <Icon.ThumbUp/>,
    ThumbUpAlt: <Icon.ThumbUpAlt/>,
    Timelapse: <Icon.Timelapse/>,
    Timeline: <Icon.Timeline/>,
    Timer: <Icon.Timer/>,
    Timer10: <Icon.Timer10/>,
    Timer3: <Icon.Timer3/>,
    TimerOff: <Icon.TimerOff/>,
    TimeToLeave: <Icon.TimeToLeave/>,
    Title: <Icon.Title/>,
    Toc: <Icon.Toc/>,
    Today: <Icon.Today/>,
    ToggleOff: <Icon.ToggleOff/>,
    ToggleOn: <Icon.ToggleOn/>,
    Toll: <Icon.Toll/>,
    Tonality: <Icon.Tonality/>,
    TouchApp: <Icon.TouchApp/>,
    Toys: <Icon.Toys/>,
    TrackChanges: <Icon.TrackChanges/>,
    Traffic: <Icon.Traffic/>,
    Train: <Icon.Train/>,
    Tram: <Icon.Tram/>,
    TransferWithinAStation: <Icon.TransferWithinAStation/>,
    Transform: <Icon.Transform/>,
    TransitEnterexit: <Icon.TransitEnterexit/>,
    Translate: <Icon.Translate/>,
    TrendingDown: <Icon.TrendingDown/>,
    TrendingFlat: <Icon.TrendingFlat/>,
    TrendingUp: <Icon.TrendingUp/>,
    TripOrigin: <Icon.TripOrigin/>,
    Tune: <Icon.Tune/>,
    TurnedIn: <Icon.TurnedIn/>,
    TurnedInNot: <Icon.TurnedInNot/>,
    Tv: <Icon.Tv/>,
    TvOff: <Icon.TvOff/>,
    Unarchive: <Icon.Unarchive/>,
    Undo: <Icon.Undo/>,
    UnfoldLess: <Icon.UnfoldLess/>,
    UnfoldMore: <Icon.UnfoldMore/>,
    Unsubscribe: <Icon.Unsubscribe/>,
    Update: <Icon.Update/>,
    Usb: <Icon.Usb/>,
    VerifiedUser: <Icon.VerifiedUser/>,
    VerticalAlignBottom: <Icon.VerticalAlignBottom/>,
    VerticalAlignCenter: <Icon.VerticalAlignCenter/>,
    VerticalAlignTop: <Icon.VerticalAlignTop/>,
    VerticalSplit: <Icon.VerticalSplit/>,
    Vibration: <Icon.Vibration/>,
    VideoCall: <Icon.VideoCall/>,
    Videocam: <Icon.Videocam/>,
    VideocamOff: <Icon.VideocamOff/>,
    VideogameAsset: <Icon.VideogameAsset/>,
    VideoLabel: <Icon.VideoLabel/>,
    VideoLibrary: <Icon.VideoLibrary/>,
    ViewAgenda: <Icon.ViewAgenda/>,
    ViewArray: <Icon.ViewArray/>,
    ViewCarousel: <Icon.ViewCarousel/>,
    ViewColumn: <Icon.ViewColumn/>,
    ViewComfy: <Icon.ViewComfy/>,
    ViewCompact: <Icon.ViewCompact/>,
    ViewDay: <Icon.ViewDay/>,
    ViewHeadline: <Icon.ViewHeadline/>,
    ViewList: <Icon.ViewList/>,
    ViewModule: <Icon.ViewModule/>,
    ViewQuilt: <Icon.ViewQuilt/>,
    ViewStream: <Icon.ViewStream/>,
    ViewWeek: <Icon.ViewWeek/>,
    Vignette: <Icon.Vignette/>,
    Visibility: <Icon.Visibility/>,
    VisibilityOff: <Icon.VisibilityOff/>,
    VoiceChat: <Icon.VoiceChat/>,
    Voicemail: <Icon.Voicemail/>,
    VoiceOverOff: <Icon.VoiceOverOff/>,
    VolumeDown: <Icon.VolumeDown/>,
    VolumeMute: <Icon.VolumeMute/>,
    VolumeOff: <Icon.VolumeOff/>,
    VolumeUp: <Icon.VolumeUp/>,
    VpnKey: <Icon.VpnKey/>,
    VpnLock: <Icon.VpnLock/>,
    Wallpaper: <Icon.Wallpaper/>,
    Warning: <Icon.Warning/>,
    Watch: <Icon.Watch/>,
    WatchLater: <Icon.WatchLater/>,
    Waves: <Icon.Waves/>,
    WbAuto: <Icon.WbAuto/>,
    WbCloudy: <Icon.WbCloudy/>,
    WbIncandescent: <Icon.WbIncandescent/>,
    WbIridescent: <Icon.WbIridescent/>,
    WbSunny: <Icon.WbSunny/>,
    Wc: <Icon.Wc/>,
    Web: <Icon.Web/>,
    WebAsset: <Icon.WebAsset/>,
    Weekend: <Icon.Weekend/>,
    Whatshot: <Icon.Whatshot/>,
    WhereToVote: <Icon.WhereToVote/>,
    Widgets: <Icon.Widgets/>,
    Wifi: <Icon.Wifi/>,
    WifiLock: <Icon.WifiLock/>,
    WifiOff: <Icon.WifiOff/>,
    WifiTethering: <Icon.WifiTethering/>,
    Work: <Icon.Work/>,
    WorkOff: <Icon.WorkOff/>,
    WorkOutline: <Icon.WorkOutline/>,
    WrapText: <Icon.WrapText/>,
    YoutubeSearchedFor: <Icon.YoutubeSearchedFor/>,
    ZoomIn: <Icon.ZoomIn/>,
    ZoomOut: <Icon.ZoomOut/>,
    ZoomOutMap: <Icon.ZoomOutMap/>,
};

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const IconHolder = styled(Grid)`
  margin: 0.5rem 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  p {
    vertical-align: middle;
    display: flex;
  }

  svg {
    margin-right: 0.5rem;
    margin-top: -0.1rem;
  }
`;

function Icons() {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Material Icons
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Material Icons by @mui/icons-material
                </Typography>
                <Paper pt={6}>
                    <Grid container spacing={6}>
                        {Object.keys(icons).map((key: string) => {
                            return (
                                <IconHolder key={key} item md={3}>
                                    <Typography variant="body2">
                                        {icons[key]} {key}
                                    </Typography>
                                </IconHolder>
                            );
                        })}
                    </Grid>
                </Paper>
            </CardContent>
        </Card>
    );
}

function MaterialIcons() {
    return (
        <React.Fragment>
            <Helmet title="Material Icons"/>
            <Typography variant="h3" gutterBottom display="inline">
                Material Icons
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <MuiLink component={NavLink} to="/">
                    Dashboard
                </MuiLink>
                <MuiLink component={NavLink} to="/">
                    Icons
                </MuiLink>
                <Typography>Material Icons</Typography>
            </Breadcrumbs>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Icons/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default MaterialIcons;
