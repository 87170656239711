import React from "react";
import styled from "styled-components/macro";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import {Badge, Grid, Typography} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {LOCAL_TOKEN_KEY} from "../../utils/constants";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
    margin-right: ${(props) => props.theme.spacing(1)};

    span {
        background-color: ${(props) =>
            props.theme.sidebar.footer.online.background};
        border: 1.5px solid ${(props) => props.theme.palette.common.white};
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }
`;

const SidebarFooter = ({...rest}) => {

    let userName = localStorage.getItem(LOCAL_TOKEN_KEY.USER_NAME)
    const navigate = useNavigate();

    return (
        <Footer {...rest}>
            <Grid container spacing={2} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                <Grid item>
                    <FooterBadge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <FooterText variant="body2">Bienvenido, {userName}</FooterText>
                    </FooterBadge>
                </Grid>
                <Grid item>
                    <button
                        style={{
                            height: 40,
                            width: 40,
                            borderRadius: 20,
                            border: 'none',
                            marginLeft: 30,
                            marginRight: 10,
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}>
                        <PowerSettingsNewIcon/>
                    </button>
                </Grid>
            </Grid>
        </Footer>
    );
};

export default SidebarFooter;
