import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Alert,
    Breadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Grid,
    Link,
    Snackbar,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import ActivityButton from "../../components/unit/ActivityButton";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {deleteUsersRepository, getUserByIdRepository, updateUserRepository} from "../../data/repository/user";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function UsersDetail() {

    const {id} = useParams();
    const {state} = useLocation();
    const navigate = useNavigate();

    const [userName, setUserName] = useState(state?.name);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarText, setSnackbarText] = useState("");
    const [user, setUser] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);

    const [inputs, setInputs] = useState({
        userName: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nombre usuario (solo para usuarios del CMS)",
                value: "",
                onChange: handleChangeInputs
            }
        },
        familyName: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nombre de família (usuarios de la APP)",
                value: "",
                onChange: handleChangeInputs
            }
        },
        email: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Email",
                value: "",
                onChange: handleChangeInputs,
            }
        },
        region: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Ubicación",
                value: "",
                onChange: () => alert("No se puede editar la ubicación"),
            }
        },
        registerDate: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Fecha de registro",
                value: "",
                onChange: () => alert("No se puede editar la fecha de registro"),
            }
        },
        forcePremium: {
            type: CustomTextFieldTypes.CHECKBOX,
            options: {
                label: "Forzar funciones premium",
                value: false,
                onChange: handleChangeInputs,
            }
        },
        generatedMenus: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Menús generados",
                value: "",
                onChange: () => alert("No se puede editar el número de menús generados"),
            }
        },
        trialMenusLeft: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Menús de prueba restantes",
                value: "",
                onChange: () => alert("No se puede editar el número de menús de prueba restantes"),
            }
        },
        socialLogin: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Login social",
                value: "",
                onChange: () => alert("No se puede editar el login social"),
            }
        },
        passwordRecoveryInProgress: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Recuperación de contraseña en progreso",
                value: "",
                onChange: () => alert("No se puede editar la recuperación de contraseña en progreso"),
            }
        },
        subscriptionExpiration: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Fecha de expiración de la suscripción",
                value: "",
                onChange: () => alert("No se puede editar la fecha de expiración de la suscripción"),
            }
        }
    })


    useEffect(() => {
        fetchAllData()
    }, []);

    const fetchAllData = () => {
        getUserByIdRepository(id)
            .then((data) => {
                 updateData(data)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Ha habido un error obteniendo el usuario");
                setSnackbar(true);
            })

    }

    const updateData = (data) => {
        setUser(data)
        if (data) {
            const newInputs = {...inputs};
            Object.keys(newInputs).forEach((key) => {
                newInputs[key].options.value = data[key];
            })
            setInputs(newInputs)
        }

        if (data.user) {
            setUserName(data.user.name);
        }
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }


    const deleteUser = (id) => {
        const res = window.confirm("¿Seguro que quieres borrar el usuario? Esta operación no es reversible.")
        if (!res) return;
        deleteUsersRepository([id])
            .then((response) => {
                setSnackbarSeverity("success");
                setSnackbarText("Usuario eliminado correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                navigate(`/dashboard/users`);
            })
            .catch((error) => {
                setSnackbarSeverity("error");
                setSnackbarText("Ha habido un error borrando el usuario");
                setSnackbar(true);
                setIsUpdating(false);
            })
    }

    const save = () => {
        setIsUpdating(true);
        const userId = id === "new" ? null : id
        updateUserRepository(userId, inputs.userName.options.value.trim(), inputs.email.options.value.trim(), inputs.region.options.value.trim(), inputs.forcePremium.options.value, inputs.familyName.options.value.trim())
            .then((user) => {
                setSnackbarSeverity("success");
                setSnackbarText("Usuario guardado correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                if (id === "new") {
                    navigate(`/dashboard/users/${user.id}`);
                } else {
                    fetchAllData()
                }
            })
            .catch((err) => {
                setSnackbarSeverity("error");
                setSnackbarText(err);
                setSnackbar(true);
                setIsUpdating(false);
            })
    }

    return (
        <React.Fragment>
            <Helmet title="Recetas"/>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Añadir/editar usuario
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                        <Link component={NavLink} to="/dashboard">
                            CMS
                        </Link>
                        <Link component={NavLink} to="/dashboard/users">
                            Usuarios
                        </Link>
                        <Typography>{userName}</Typography>
                    </Breadcrumbs>
                </div>

                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 12}}>
                    {id !== "new" &&
                        <ActivityButton title={"Borrar"} variant={'outlined'} onClick={() => deleteUser(id)}/>}
                    <ActivityButton title={"Guardar"} isUpdating={isUpdating} onClick={save}/>
                </div>

            </div>

            <Divider my={3}/>

            <Grid container spacing={6}>
                <Grid direction={'column'} item spacing={6} >
                    {Object.keys(inputs).map((key, index) => {
                        if (index > 4) return <></>;
                            const input = inputs[key];
                            return (
                                <Grid key={index} item md={4} style={{minWidth: 400, marginBottom: 20}}>
                                    <Card style={{padding: 24}}>
                                        <CustomTextField type={input.type} options={{...input.options, name: key}}/>
                                    </Card>
                                </Grid>
                            )
                        }
                    )}

                </Grid>

                <Grid item spacing={6}>
                    <Card style={{padding: 24, width: 400, marginBottom: 20}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="h5" gutterBottom display="inline">
                                Miembros del grupo
                            </Typography>
                            {user.commensals?.map((user) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <p><span style={{color: '#F7972F'}}>•</span> {user.userName}</p>
                                        <div style={{display: 'flex', flexDirection: 'row', width: 200}}>
                                            {user.allergens.map((allergen) => {
                                                return (
                                                    <p style={{textOverflow: 'ellipsis'}}>{allergen.name},</p>
                                                )
                                            })}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </Card>

                    {Object.keys(inputs).map((key, index) => {
                        if (index < 6 || index > 9) return <></>;
                        const input = inputs[key];
                        return (
                            <Grid key={index} item md={4} style={{minWidth: 400, marginBottom: 20}}>
                                <Card style={{padding: 24}}>
                                    <CustomTextField type={input.type} options={{...input.options, name: key}}/>
                                </Card>
                            </Grid>
                        )
                    })}

                </Grid>


                <Grid direction={'column'} item spacing={6} >

                    <Grid style={{marginBottom: 20, width: 400}}>
                        <Card style={{padding: 24}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography variant="h5" gutterBottom display="inline">
                                    Tipo de registro
                                </Typography>
                                <Typography variant="h4" gutterBottom display="inline">
                                    {user.registerType?.toUpperCase()}
                                </Typography>
                            </div>
                        </Card>
                    </Grid>

                    {user.paymentType && <Grid style={{marginBottom: 20, width: 400}}>
                        <Card style={{padding: 24}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography variant="h5" gutterBottom display="inline">
                                    Plan suscrito:
                                </Typography>
                                <Typography variant="h4" gutterBottom display="inline">
                                    {user.paymentType?.toUpperCase()}
                                </Typography>
                            </div>
                        </Card>
                    </Grid>}

                    <Grid style={{marginBottom: 20}}>
                        <Card style={{padding: 24, width: 400}}>
                            <CustomTextField type={CustomTextFieldTypes.CHECKBOX}
                                             options={{...inputs['forcePremium']?.options, name: "forcePremium"}}/>
                            <p>Activando esta casilla el usuario tendrá acceso a todas las funciones de la APP <strong>sin
                                necesidad de suscripción</strong>.</p>
                        </Card>
                    </Grid>

                    <Grid item md={4} style={{minWidth: 400, marginBottom: 20}}>
                        <Card style={{padding: 24}}>
                            <CustomTextField type={inputs.subscriptionExpiration.type} options={{
                                ...inputs.subscriptionExpiration.options,
                                name: "subscriptionExpiration"
                            }}/>
                        </Card>
                    </Grid>

                </Grid>

            </Grid>

            <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarText}
                </Alert>
            </Snackbar>

        </React.Fragment>
    );
}

export default UsersDetail;

