export const LOCAL_TOKEN_KEY = {
    USER_NAME: 'USER_NAME'
}

export const WEEK_DAYS = [
    {
        value: 0,
        label: 'Lunes'
    },
    {
        value: 1,
        label: 'Martes'
    },
    {
        value: 2,
        label: 'Miércoles'
    },
    {
        value: 3,
        label: 'Jueves'
    },
    {
        value: 4,
        label: 'Viernes'
    },
    {
        value: 5,
        label: 'Sábado'
    },
    {
        value: 6,
        label: 'Domingo'
    },
]

export const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
]
