import React, {useState} from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async";

import {
    Avatar,
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Button,
    Card as MuiCard,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider as MuiDivider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Paper as MuiPaper,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {Add as AddIcon, Person as PersonIcon} from "@mui/icons-material";
import {spacing} from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
    const {onClose, selectedValue, open} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
            <List>
                {emails.map((email) => (
                    <ListItem
                        button
                        onClick={() => handleListItemClick(email)}
                        key={email}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={email}/>
                    </ListItem>
                ))}
                <ListItem
                    autoFocus
                    button
                    onClick={() => handleListItemClick("addAccount")}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <AddIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Add account"/>
                </ListItem>
            </List>
        </Dialog>
    );
}

function SimpleDialogDemo() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Simple Dialogs
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Simple dialogs can provide additional details or actions about a list
                    item.
                </Typography>

                <Paper mt={4}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Open simple dialog
                    </Button>
                    <SimpleDialog
                        selectedValue={selectedValue}
                        open={open}
                        onClose={handleClose}
                    />
                    <Paper mt={2}>
                        <Typography variant="body2">Selected: {selectedValue}</Typography>
                    </Paper>
                </Paper>
            </CardContent>
        </Card>
    );
}

function AlertDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Alerts
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Alerts are urgent interruptions, requiring acknowledgement, that
                    inform the user about a situation.
                </Typography>

                <Paper mt={4}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Open alert dialog
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Use Google's location service?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Let Google help apps determine location. This means sending
                                anonymous location data to Google, even when no apps are
                                running.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Disagree
                            </Button>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                Agree
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </CardContent>
        </Card>
    );
}

function MaxWidthDialog() {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("sm");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        // @ts-expect-error autofill of arbitrary value is not handled.
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Optional Sizes
                </Typography>
                <Typography variant="body2" gutterBottom>
                    You can set a dialog maximum width by using the maxWidth enumerable in
                    combination with the fullWidth boolean.
                </Typography>

                <Paper mt={4}>
                    <React.Fragment>
                        <Button variant="outlined" onClick={handleClickOpen}>
                            Open max-width dialog
                        </Button>
                        <Dialog
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle>Optional sizes</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You can set my maximum width and whether to adapt or not.
                                </DialogContentText>
                                <Box
                                    noValidate
                                    component="form"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        m: "auto",
                                        width: "fit-content",
                                    }}
                                >
                                    <FormControl sx={{mt: 2, minWidth: 120}}>
                                        <InputLabel htmlFor="max-width">maxWidth</InputLabel>
                                        <Select
                                            autoFocus
                                            value={maxWidth}
                                            onChange={handleMaxWidthChange}
                                            label="maxWidth"
                                            inputProps={{
                                                name: "max-width",
                                                id: "max-width",
                                            }}
                                        >
                                            <MenuItem value={false}>false</MenuItem>
                                            <MenuItem value="xs">xs</MenuItem>
                                            <MenuItem value="sm">sm</MenuItem>
                                            <MenuItem value="md">md</MenuItem>
                                            <MenuItem value="lg">lg</MenuItem>
                                            <MenuItem value="xl">xl</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel
                                        sx={{mt: 1}}
                                        control={
                                            <Switch
                                                checked={fullWidth}
                                                onChange={handleFullWidthChange}
                                            />
                                        }
                                        label="Full width"
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                </Paper>
            </CardContent>
        </Card>
    );
}

function FormDialog() {
    const [open, setOpen] = useState(false);

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Form Dialogs
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Form dialogs allow users to fill out form fields within a dialog.
                </Typography>

                <Paper mt={4}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpen(true)}
                        >
                            Open form dialog
                        </Button>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    To subscribe to this website, please enter your email address
                                    here. We will send updates occasionally.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    Subscribe
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Paper>
            </CardContent>
        </Card>
    );
}

function Dialogs() {
    return (
        <React.Fragment>
            <Helmet title="Dialogs"/>
            <Typography variant="h3" gutterBottom display="inline">
                Dialogs
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/">
                    Components
                </Link>
                <Typography>Dialogs</Typography>
            </Breadcrumbs>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <SimpleDialogDemo/>
                    <AlertDialog/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MaxWidthDialog/>
                    <FormDialog/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Dialogs;
