import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {Formik} from "formik";

import {Alert as MuiAlert, Button, TextField as MuiTextField,} from "@mui/material";
import {spacing} from "@mui/system";
import {loginRepository} from "../../data/repository/auth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
    const navigate = useNavigate();
    const signIn = (email, password) => {
        localStorage.clear();
        loginRepository(email, password)
            .then((response) => {
                console.log(response);
                navigate("/dashboard/home");
            })
            .catch(error => {
                console.error(error);
                alert('Error en el login. Asegúrese que los datos son correctos.')
            })
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                try {
                    await signIn(values.email, values.password);
                } catch (error) {
                    const message = error.message || "Error";

                    setStatus({success: false});
                    setErrors({submit: message});
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <TextField
                        type="email"
                        name="email"
                        label="Email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={2}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="Contraseña"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={2}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        Iniciar sesión
                    </Button>
                </form>
            )}
        </Formik>
    );
}

export default SignIn;
