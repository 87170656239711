import {deleteRecipeApi, getRecipeApi, getRecipesApi, saveRecipeApi, uploadRecipeImageApi} from "../../api/recipe";
import {getAllergensApi} from "../../api/allergen";
import {
    getIngredientsFromRecipeFormatter,
    getIngredientsNameFormatterFromApi,
    getRecipeFormatterFromApi,
    getRecipesFormatterFromApi,
    getRecipesNameFormatterFromApi, getRecipesPrintFormatterFromApi,
    postRecipesFormatterToApi
} from "../../formatter/recipe";
import {getCookingTypesRepository, getIngredientsListRepository} from "../ingredients";

export const getRecipesRepository = () => {

    const getRecipes = new Promise((resolve, reject) => {
        getRecipesApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al obtener las recetas");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al obtener las recetas");
            });
    });

    const getAllergens = new Promise((resolve, reject) => {
        getAllergensApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al obtener los alérgenos");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al obtener los alérgenos");
            });
    })

    return Promise.all([getRecipes, getAllergens])
        .then((response) => {
            return getRecipesFormatterFromApi(response)
        })
        .catch((error) => {
            throw new Error(error)
        })
};

export const getRecipeRepository = (recipeId) => {

    const recipe = new Promise((resolve, reject) => {
        getRecipeApi(recipeId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data?.res;
                    if (responseData) resolve(getRecipeFormatterFromApi(responseData));
                } else throw new Error("Error al obtener las recetas");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al obtener las recetas");
            });
    });

    const recipes = getRecipesRepository();

    const cookingTypes = getCookingTypesRepository();

    const ingredients = getIngredientsListRepository();

    return Promise.all([recipe, recipes, cookingTypes, ingredients])
        .then((response) => {
            response[0] = getIngredientsFromRecipeFormatter(response[0], response[3])
            response[1] = getRecipesNameFormatterFromApi(response[1])
            response[3] = getIngredientsNameFormatterFromApi(response[3])
            return response
        })
        .catch((error) => {
            throw new Error(error)
        })
}

export const getRecipesByIdRepository = async (recipeList) => {

    let recipesPromises = [];

    recipeList.forEach(recipe => {
        recipesPromises.push(new Promise((resolve, reject) => {
            getRecipeApi(recipe)
                .then(res => {
                    if (res.status === 200) {
                        const responseData = res.data?.res;
                        if (responseData) resolve(getRecipesPrintFormatterFromApi(responseData));
                    } else throw new Error("Error al obtener las recetas");
                })
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        reject(err.response.data?.error);
                    }
                    reject("Error al obtener las recetas");
                });
        })  )
    })
    const ingredients = getIngredientsListRepository();

    return Promise.all([ingredients].concat(recipesPromises))
        .then((response) => {

            let ingredientList = response.shift();
            let recipesToPrint = response

            recipesToPrint.forEach((newRecipe, index) => {
                newRecipe.ingredients.forEach(ingredient => {
                    ingredientList.find((option) => {
                        ingredient.name = ingredientList.find((el) => el.id === ingredient.id)?.name;
                    })
                })
            })

            return recipesToPrint
        })
        .catch((error) => {
            throw new Error(error)
        })
}

export const saveRecipeRepository = (recipeId, recipe, recipeToBeDuplicated) => {
    return new Promise((resolve, reject) => {
        saveRecipeApi(recipeId, postRecipesFormatterToApi(recipe), recipeToBeDuplicated)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data?.res;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al guardar la receta");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.msg);
                }
                reject("Error al guardar la receta");
            });
    })
}

export const uploadRecipeImageRepository = (recipeId, file, fileName) => {
    return new Promise((resolve, reject) => {
        uploadRecipeImageApi(recipeId, file, fileName)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al subir la imagen");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.msg);
                }
                reject("Error al subir la imagen");
            });
    })
}


export const deleteRecipesRepository = (recipesToDelete) => {

    let promises = []

    recipesToDelete.map((recipeId) => {
        const deleteIngredient = new Promise((resolve, reject) => {
            deleteRecipeApi(recipeId)
                .then(res => {
                    if (res.status === 200) {
                        const responseData = res.data;
                        if (responseData) resolve(responseData);
                    } else throw new Error("Error al eliminar el ingrediente");
                })
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        reject(err.response.data?.error);
                    }
                    reject("Error en el login. Asegúrese que los datos son correctos.");
                });
        })
        promises.push(deleteIngredient)
    })

    return Promise.all(promises)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw new Error(error)
        })

};
