import React, {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {getRecipesByIdRepository} from "../../data/repository/recipe";
import ActivityButton from "../../components/unit/ActivityButton";

const PrintRecipes = () => {

    const [recipesDetail, setRecipesDetail] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const recipesToPrint = JSON.parse(sessionStorage.getItem('print-recipes'));
    const printRef = useRef(null);

    useEffect(() => {
        getRecipesInformation()
    },[])


    const getRecipesInformation = () => {
        getRecipesByIdRepository(recipesToPrint)
            .then(res => {
                console.log(res);
                setRecipesDetail(res)
                setIsLoading(false)
                handlePrint();
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err);
                alert('Error al obtener la información de las recetas. Cierre la pestaña y vuelva a intentarlo.')
            })
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    if (recipesToPrint) {
        return (
            <>
                {isLoading ? <div>Cargando...</div>
                    : <div>
                        <ActivityButton title={'Imprimir'} onClick={handlePrint}/>
                        <div style={{overflow: 'hidden'}} ref={printRef}>
                            {recipesDetail.map((recipe) => {

                                return (
                                    <div style={{height: 950, width: 595}}>
                                        <h1>{recipe.name}</h1>

                                        <h2>Listado de ingredientes</h2>
                                        <ul>
                                            {recipe.ingredients.map(ingredient => {
                                                return (
                                                    <li>{ingredient.name + " - " + ingredient.quantity}{ingredient.integerUnits ? "ud." : "gr."}</li>
                                                )
                                            })}
                                        </ul>

                                        <h2>Detalle de la receta</h2>
                                        <div dangerouslySetInnerHTML={{__html: recipe.detail}}/>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </>
        )
    } else {
        return (
            <div>
                <h1>No hay recetas que imprimir</h1>
            </div>
        )
    }

}

export default PrintRecipes;
