import React from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {Divider as MuiDivider, Grid, Typography as MuiTypography,} from "@mui/material";
import {spacing} from "@mui/system";
import {green, red} from "@mui/material/colors";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Home() {

    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <Grid justifyContent="space-between" container spacing={6}>
                <Grid item>
                    <Typography variant="h3" gutterBottom>
                        ¡Bienvenido!
                    </Typography>
                    <Typography variant="subtitle1">
                        Veamos cómo va La Menuteka
                    </Typography>
                </Grid>
            </Grid>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title="Sales Today"
                        amount="2.532"
                        chip="Today"
                        percentagetext="+26%"
                        percentagecolor={green[500]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title="Visitors"
                        amount="170.212"
                        chip="Annual"
                        percentagetext="-14%"
                        percentagecolor={red[500]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title="Total Earnings"
                        amount="$ 24.300"
                        chip="Monthly"
                        percentagetext="+18%"
                        percentagecolor={green[500]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <Stats
                        title="Pending Orders"
                        amount="45"
                        chip="Yearly"
                        percentagetext="-9%"
                        percentagecolor={red[500]}
                        illustration="/static/img/illustrations/waiting.png"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} lg={8}>
                    <LineChart/>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DoughnutChart/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} lg={4}>
                    <BarChart/>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Table/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Home;
