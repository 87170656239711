import _ from "lodash";

export const getRecipesFormatterFromApi = (data) => {

    let recipesList = [];
    let allergensList = data[1].res;


    const transformAllergenIdToName = (id) => {

        let allergenObject = allergensList.find((allergen) => allergen.id === id)

        return allergenObject.name
    }

    data[0]?.res?.map((recipe, index) => {

        let recipeObject = {
            id: recipe.id,
            name: recipe.name,
            familyName: recipe.mainIngredient || "-",
            sustitutiveRecipe: recipe.sustitutiveRecipe || "-",
            allergens: recipe.allergenIds || [],
            cookingTime: recipe.cookingTime + " min"
        }

        if (recipe.allergenIds.length) {

            let newAllergensArray = recipeObject.allergens;

            recipe?.allergenIds?.map((allergenId, index) => {
                newAllergensArray[index] = transformAllergenIdToName(allergenId)
            })

            newAllergensArray.join(',');
            recipeObject.allergens = newAllergensArray;

        } else {
            recipeObject.allergens = '-';
        }
        recipesList.push(recipeObject);
    })

    return recipesList;

}

export const getRecipesPrintFormatterFromApi = (data) => {
    return {
        ...data,
        sustitutiveRecipe: data.sustitutiveRecipe?.id,
    };
}

export const getRecipeFormatterFromApi = (data) => {
    return {
        ...data,
        sustitutiveRecipe: {
            value: data.sustitutiveRecipe?.id,
            label: data.sustitutiveRecipe?.name,
            optionValue: data.sustitutiveRecipe?.name,
            inputValue: data.sustitutiveRecipe?.id,
        },
    };
}


export const getRecipesNameFormatterFromApi = (data) => {
    const res = [];
    data.forEach(recipe => {
        res.push({
            value: recipe.id,
            label: recipe.name,
            optionValue: recipe.name,
            inputValue: recipe.id,
        })
    })
    return res.sort((a, b) => a.label.localeCompare(b.label));
}


export const getIngredientsNameFormatterFromApi = (data) => {
    const res = [];
    data.forEach(recipe => {
        res.push({
            value: recipe.id,
            label: recipe.name,
            optionValue: recipe.name,
            inputValue: recipe.id,
        })
    })
    return res.sort((a, b) => a.label.localeCompare(b.label));
}

export const getIngredientsFromRecipeFormatter = (recipe, ingredients) => {
    for (let i = 0; i < recipe.ingredients.length; i++) {
        let recipeIngredient = recipe.ingredients[i];

        for (let j = 0; j < ingredients.length; j++) {
            const ingredient = ingredients[j]

            if (recipeIngredient.id === ingredient.id) {
                recipeIngredient.ingredientInfo = ingredient
                break;
            }
        }
    }
    return recipe
}

export const postRecipesFormatterToApi = (recipe) => {
    let newRecipe = recipe

    newRecipe.name = newRecipe.name.trim();

    return {
        ...newRecipe,
        sustitutiveRecipeId: newRecipe.sustitutiveRecipe.value,
        cookingTime: _.toNumber(recipe.cookingTime)
    }
}
