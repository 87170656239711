import moment from "moment";

export const getUsersListFormatterFromApi = (data) => {

    let newUsersArray = []

    for (let i = 0; i < data.length; i++){
        const user = data[i];

        newUsersArray.push({
            id: user.id,
            user: user.userName || '',
            email: user.email || '',
            groupMembers: user.commensals || 1,
            subscribed: user.subscribed,
            familyName: user.familyName,
            registerDate: user.registerDate,
            generatedMenus: user.generatedMenus
        })
    }

    return newUsersArray
}

export const getUserByIdFormaterFromApi = (data) => {

    let newUser = {
        id: data.id,
        userName: data.userName || '',
        email: data.email || '',
        region: data.region || '',
        commensals: [],
        registerType: data.registerType || '',
        paymentType: data.paymentType || '',
        forcePremium: data.forcePremium,
        familyName: data.familyName,
        registerDate: moment(data.registerDate).format("DD/MM/YYYY HH:mm"),
        generatedMenus: data.generatedMenus,
        trialMenusLeft: data.trialMenusLeft,
        socialLogin: data.socialLogin ? "Si" : "No",
        passwordRecoveryInProgress: data.passwordRecoveryInProgress ? "Si" : "No",
        subscriptionExpiration: data.subscriptionExpiration ? moment(data.subscriptionExpiration).format("DD/MM/YYYY HH:mm") : 'Sin suscripción',
    }

    if (data.commensals.length) {
        let newCommensalsArray = []
        data.commensals.map((commensal) => {
            newCommensalsArray.push({
                id: commensal.id,
                userName: commensal.nickname,
                allergens: commensal.allergens
            })
        })

        newUser.commensals = newCommensalsArray;
    }

    return newUser
}
