import React from "react";
import {Box, CardContent, Typography} from "@mui/material";
import styled from "styled-components";

import ReactQuill, {Quill} from "react-quill";
import {htmlEditButton} from "quill-html-edit-button";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./ReactQuill.css";
import {uploadRecipeImageRepository} from "../../data/repository/recipe";

Quill.register("modules/htmlEditButton", htmlEditButton);

//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);


// Don't forget corresponding css
const Size = Quill.import("attributors/style/size");
Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
Quill.register(Size, true);

//Text indent
const Parchment = Quill.import("parchment");

class IndentAttributor extends Parchment.Attributor.Style {
    add(node, value) {
        if (value === 0) {
            this.remove(node);
            return true;
        } else {
            return super.add(node, `${value}em`);
        }
    }
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
    scope: Parchment.Scope.BLOCK,
    whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});

Quill.register(IndentStyle, true);

const QuillWrapper = styled.div`
    .ql-editor {
        min-height: 400px;
    }
`;

const modules = {
    toolbar: {
        container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            [{indent: "-1"}, {indent: "+1"}],
            ["link",],

            [{size: ["0.75em", "1em", "1.5em", "2.5em"]}],
            [{header: [1, 2, 3, 4, 5, 6, false]}],

            [{color: []}, {background: []}],
            [{font: []}],
            [{align: []}],
        ],
        handlers: {
            image: imageHandler,
        },
    },
    htmlEditButton: {
        msg: "Editar en HTML",
        okText: "Ok",
        cancelText: "Cancelar",
    },
};

let quillObj;

const FILE_UPLOAD = process.env.REACT_APP_FILES_FOLDER_PATH;

function imageHandler() {
    return;
    //DISABLED IMAGE UPLOADER
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
        try {
            const file = input.files[0];
            const formData = new FormData();
            formData.append("file", file, file.name);

            const range = quillObj.getEditorSelection();

            const res = await uploadRecipeImageRepository();

            quillObj
                .getEditor()
                .insertEmbed(range.index, "image", `${FILE_UPLOAD}/${res}`);
        } catch (error) {
            alert(error);
        }
    };
}

function ReactQuillEditor({titol = "", value = "", handleChange = () => null, name = ""}) {
    return (
        <CardContent>
            <Typography variant="h6" gutterBottom>
                {titol}
            </Typography>
            <Box mt={3}>
                <QuillWrapper>
                    <ReactQuill
                        ref={(el) => {
                            quillObj = el;
                        }}
                        theme="snow"
                        modules={modules}
                        value={value}
                        name={name}
                        onChange={(value) => {
                            handleChange({
                                target: {name: name, value: value},
                            });
                        }}
                    />
                </QuillWrapper>
            </Box>
        </CardContent>
    );
}

export default ReactQuillEditor;
