import React from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import {ExpandMore as ExpandMoreIcon} from "@mui/icons-material";
import {spacing} from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function SimpleAccordion() {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Simple Expansion Panel
                </Typography>
                <Typography variant="body2" gutterBottom>
                    A lightweight container that may either stand alone or be connected to
                    a larger surface, such as a card.
                </Typography>
                <div>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Expansion Panel 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Expansion Panel 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                                eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion disabled>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Disabled Expansion Panel</Typography>
                        </AccordionSummary>
                    </Accordion>
                </div>
                {" "}
            </CardContent>
        </Card>
    );
}

function ControlledAccordion() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Controlled Expansion Panel
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Extend the default panel behavior to create an accordion with the
                    Accordion component.
                </Typography>
                <div>
                    <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>General settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
                                feugiat. Aliquam eget maximus est, id dignissim quam.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Users</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Donec placerat, lectus sed mattis semper, neque lectus feugiat
                                lectus, varius pulvinar diam eros in elit. Pellentesque
                                convallis laoreet laoreet.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Advanced settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                                Integer sit amet egestas eros, vitae egestas augue. Duis vel est
                                augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Personal data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                                Integer sit amet egestas eros, vitae egestas augue. Duis vel est
                                augue.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                {" "}
            </CardContent>
        </Card>
    );
}

function Accordions() {
    return (
        <React.Fragment>
            <Helmet title="Expansion Panels"/>
            <Typography variant="h3" gutterBottom display="inline">
                Expansion Panels
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/">
                    Components
                </Link>
                <Typography>Expansion Panels</Typography>
            </Breadcrumbs>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <SimpleAccordion/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControlledAccordion/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Accordions;
