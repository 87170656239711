import axiosService from "../../axiosService";


export const getUsersListApi = () => {
    const url = "/cms/users";
    return axiosService.get(url)
}

export const deleteUserApi = (id) => {
    const url = `/cms/user/${id}`;
    return axiosService.delete(url)
}

export const getUserByIdApi = (id) => {
    const url = `/cms/user/${id}`;
    return axiosService.get(url)
}

export const updateUserApi = (id, userName, email, region, forcePremium, familyName) => {

    const url = `/cms/user/${id}`;
    return axiosService.put(url, {
        userName,
        email,
        region,
        forcePremium,
        familyName
    })
}
