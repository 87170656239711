import {Button, CircularProgress} from "@mui/material";

export default ({title, isUpdating, onClick, disabled, variant = "contained", color = "primary"}) => {
    return (
        <Button
            disabled={isUpdating || disabled}
            variant={variant}
            onClick={onClick}
            color={color}
        >
            <div style={{display: "flex", justifyContent: 'center', alignItems: "center", gap: 8}}>
                {title}
                {isUpdating && <CircularProgress size={18} style={{color: 'white'}}/>}
            </div>
        </Button>
    )
}
