import React, {useState} from 'react';
import styled from "styled-components/macro";
import {Button, Paper, TextField as MuiTextField, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {ReactComponent as Logo} from "../../vendor/logo_black.svg";
import {spacing} from "@mui/system";
import {useNavigate, useParams} from "react-router-dom";
import {recoverPasswordRepository} from "../../data/repository/recover-password";
import {green} from "@mui/material/colors";

const RecoverPassword = () => {

    const {id} = useParams();

    const navigate = useNavigate();


    const [success, setSuccess] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);


    const TextField = styled(MuiTextField)(spacing);

    const Root = styled.div`
        max-width: 520px;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        display: flex;
        min-height: 100%;
        flex-direction: column;
    `;

    const Brand = styled(Logo)`
        fill: ${(props) => props.theme.palette.primary.main};
        width: 174px;
        height: 115px;
        text-align: center;
        margin: 0 auto ${(props) => props.theme.spacing(5)};
    `;

    const Wrapper = styled(Paper)`
        padding: ${(props) => props.theme.spacing(6)};

        ${(props) => props.theme.breakpoints.up("md")} {
            padding: ${(props) => props.theme.spacing(10)};
        }
    `;

    const handleSubmit = e => {
        e.preventDefault();
        const password = e.target[0].value;
        const repeatPassword = e.target[2].value;
        console.log(e);
        if (repeatPassword === password && password !== '') {
            setIsUpdating(true);
            recoverPasswordRepository(id, password)
                .then((response) => {
                    setIsUpdating(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    console.error(error);
                    setIsUpdating(false);
                    alert('No hemos podido guardar tu nueva contraseña. Vuelve realizar el proceso de recuperar contraseña desde el principio.')
                    navigate("/recover-email")
                })
        } else if (repeatPassword !== password) {
            alert('No coinciden los campos de la contraseña')
        }
    }

    return (
        <React.Fragment>
            <Wrapper>
                <Helmet title="Modifica tu contraseña"/>
                <div style={{width: '100%', alignItems: 'center', display: 'flex'}}>
                    <Brand/>
                </div>

                {!success && <form noValidate onSubmit={handleSubmit}>
                    <Typography component="h2" variant="body1" align="center">
                        Introduce tu nueva contraseña para volver a iniciar sesión
                    </Typography>
                    <TextField
                        type="password"
                        name="password"
                        label="Nueva Contraseña"
                        fullWidth
                        my={2}
                    />
                    <TextField
                        type="password"
                        name="repeatPassword"
                        label="Repetir la contraseña"
                        fullWidth
                        my={2}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        disabled={isUpdating}
                        style={{marginTop: 20}}
                        variant="contained"
                        color="primary"
                    >
                        Modificar tu contraseña
                    </Button>
                </form>}
                {success &&
                    <div style={{width: '100%', textAlign: "center"}}>
                        <h2 style={{color: green[400]}}>
                            ¡Contraseña cambiada!
                        </h2>
                        <p>
                            Vuelve a abrir la app y haz login con tu nueva contraseña.
                        </p>
                    </div>
                }
            </Wrapper>
        </React.Fragment>
    )

}

export default RecoverPassword;
