import {
    deleteIngredientApi,
    getCookingTypesApi,
    getIngredientApi,
    getIngredientFamiliesApi,
    getIngredientsApi,
    saveIngredientApi
} from "../../api/ingredients";
import {
    getAllergensFormatterFromApi,
    getCookingTypesFormatterFromApi,
    getIngredientFamiliesFormatterFromApi,
    getIngredientFormatterFromApi, getIngredientFormatterFromPromise,
    getIngredientsFormatterFromApi,
    saveIngredientFormatterToApi
} from "../../formatter/ingredients";
import {getAllergensApi} from "../../api/allergen";

export const getIngredientsListRepository = () => {
    return new Promise((resolve, reject) => {
        getIngredientsApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getIngredientsFormatterFromApi(responseData));
                } else throw new Error("Error al recuperar los ingredientes");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al recuperar ");
            });
    })
};

export const getIngredientFamiliesRepository = () => {
    return new Promise((resolve, reject) => {
        getIngredientFamiliesApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getIngredientFamiliesFormatterFromApi(responseData));
                } else throw new Error("Error al recuperar las famílias de ingredientes");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al recuperar ");
            });
    })
}

export const getAllergensRepository = () => {
    return new Promise((resolve, reject) => {
        getAllergensApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getAllergensFormatterFromApi(responseData));
                } else throw new Error("Error al recuperar los alérgenos de ingredientes");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al recuperar ");
            });
    })
}

export const getCookingTypesRepository = () => {
    return new Promise((resolve, reject) => {
        getCookingTypesApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getCookingTypesFormatterFromApi(responseData));
                } else throw new Error("Error al recuperar los tipos de cocción");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al recuperar ");
            });
    })
}

export const getIngredientRepository = (id) => {
    const ingredient = new Promise((resolve, reject) => {
        getIngredientApi(id)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getIngredientFormatterFromApi(responseData));
                } else throw new Error("Error al recuperar el ingrediente");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al recuperar ");
            });
    })

    const ingredientFamilies = getIngredientFamiliesRepository();
    const allergens = getAllergensRepository();
    const cookingTypes = getCookingTypesRepository();

    return Promise.all([ingredient, ingredientFamilies, allergens, cookingTypes])
        .then(r => {
            return {
                ingredient: getIngredientFormatterFromPromise(r[0], r[1]),
                ingredientFamilies: r[1],
                allergens: r[2],
                cookingTypes: r[3]
            }
        })
        .catch(error => {
            console.error(error);
            throw new Error(error);
        })
};

export const deleteIngredientRepository = (ingredientsToDelete) => {

    let promises = []

    ingredientsToDelete.map((ingredient) => {
        const deleteIngredient = new Promise((resolve, reject) => {
            deleteIngredientApi(ingredient)
                .then(res => {
                    if (res.status === 200) {
                        const responseData = res.data;
                        if (responseData) resolve(responseData);
                    } else throw new Error("Error al eliminar el ingrediente");
                })
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        reject(err.response.data?.error);
                    }
                    reject("Error en el login. Asegúrese que los datos son correctos.");
                });
        })
        promises.push(deleteIngredient)
    })

    return Promise.all(promises)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw new Error(error);
        })
};

export const saveIngredientRepository = (ingredientId, ingredient, addedNewCookingTypes) => {
    return new Promise((resolve, reject) => {
        saveIngredientApi(ingredientId, saveIngredientFormatterToApi(ingredient, addedNewCookingTypes))
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getIngredientFormatterFromApi(responseData));
                } else throw new Error("Error al guardar el ingrediente");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.msg);
                }
                reject("Error al guardar el ingrediente");
            });
    })
}
