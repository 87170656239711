export const getIngredientsFormatterFromApi = (data) => {
    return data?.res.map(ingredient => {
        return {
            ...ingredient,
            optionValue: ingredient.name,
            inputValue: ingredient.id,
        }
    })
}

export const getIngredientFormatterFromApi = (data) => {
    let ingredient = data?.res;
    ingredient.nutritionalInfo = ingredient.nutritionalInfo.length > 0 ? ingredient.nutritionalInfo.map((e, index) => {
        return {id: index, ...nutritionalInfoFormatterFromApi(e)};
    }) : []
    return ingredient
}

export const saveIngredientFormatterToApi = (ingredient, addedNewCookingTypes) => {
    console.log(ingredient, addedNewCookingTypes)
    return {
        name: ingredient.name.trim(),
        familyId: ingredient.familyId.value,
        allergenIds: ingredient.allergenIds,
        nutritionalInfo: ingredient.nutritionalInfo.length > 0 ? ingredient.nutritionalInfo.map((nT) => nutritionalInfoFormatterToApi(nT, addedNewCookingTypes)) : []
    }
}

const nutritionalInfoFormatterToApi = (nutritionalInfo, addedNewCookingTypes) => {
    const cookingType = addedNewCookingTypes.find(ct => ct.id === nutritionalInfo.cookingTypeId);
    if (cookingType) {
        return {
            cookingTypeName: cookingType.label,
            proteins: nutritionalInfo.proteins,
            carbohydrates: nutritionalInfo.carbohydrates,
            fats: nutritionalInfo.fats,
            kcal: nutritionalInfo.kcal
        }
    } else {
        return {
            cookingTypeId: nutritionalInfo.cookingTypeId,
            proteins: nutritionalInfo.proteins,
            carbohydrates: nutritionalInfo.carbohydrates,
            fats: nutritionalInfo.fats,
            kcal: nutritionalInfo.kcal
        }
    }
}

const nutritionalInfoFormatterFromApi = (nutritionalInfo) => {
    return nutritionalInfo;
}


export const getIngredientFamiliesFormatterFromApi = (data) => {
    let resData = [];
    data?.res.forEach(ingFamily => {
        resData.push({
            value: ingFamily.id,
            label: ingFamily.name,
            optionValue: ingFamily.name,
            inputValue: ingFamily.id,
        })
    })
    return resData
}


export const getAllergensFormatterFromApi = (data) => {
    let resData = [];
    data?.res.forEach(ingFamily => {
        resData.push({
            value: ingFamily.id,
            label: ingFamily.name,
            icon: ingFamily.icon
        })
    })
    return resData
}


export const getCookingTypesFormatterFromApi = (data) => {
    let resData = [];
    data?.res.forEach(ingFamily => {
        resData.push({
            id: ingFamily.id,
            value: ingFamily.id,
            label: ingFamily.type,
        })
    })
    return resData
}

export const getIngredientFormatterFromPromise = (ingredient, ingredientFamilyList) => {
    const ingredientFamily = ingredientFamilyList.find(item => item.value === ingredient.familyId);
    return { ...ingredient, familyId: ingredientFamily}
};
