import axiosService from "../../axiosService";

export const getRecipesApi = () => {
    const url = "/cms/recipes"
    return axiosService.get(url)
}

export const getRecipeApi = (recipeId) => {
    const url = `/cms/recipe/${recipeId}`
    return axiosService.get(url)
}

export const saveRecipeApi = (recipeId, recipe, recipeToBeDuplicated) => {
    let url = `/cms/recipe/`;
    if (recipeId !== null && !recipeToBeDuplicated) {
        url = `/cms/recipe/${recipeId}`;
        return axiosService.put(url, recipe)
    }
    return axiosService.post(url, recipe)
}

export const uploadRecipeImageApi = (recipeId, file, fileName) => {
    let url = `/cms/recipe-image/${recipeId}`;
    return axiosService.post(url, file, fileName, {headers: {"Content-Type": "multipart/form-data",}});

}

export const deleteRecipeApi = (recipe) => {
    const url = `/cms/recipe/${recipe}`;
    return axiosService.delete(url)
}
