import {deleteUserApi, getUserByIdApi, getUsersListApi, updateUserApi} from "../../api/user";
import {getUserByIdFormaterFromApi, getUsersListFormatterFromApi} from "../../formatter/user";


export const getUsersListRepository = () => {
    return new Promise((resolve, reject) => {
        getUsersListApi()
            .then((response) => {
                if (response.status === 200) {
                    const responseData = response.data.res;
                    if (responseData) resolve(getUsersListFormatterFromApi(responseData));
                } else throw new Error("Error al obtener el listado de usuarios");
            })
            .catch((error) => {
                console.error(error)
                if (error.response) {
                    reject(error.response.data?.msg);
                }
                reject("Error al obtener el listado de usuarios");
            })
    })
}

export const deleteUsersRepository = (usersToDelete) => {

    let promises = []

    usersToDelete.map((user) => {
        const deleteUser = new Promise((resolve, reject) => {

            deleteUserApi(user)
                .then(res => {
                    if (res.status === 200) {
                        const responseData = res.data;
                        if (responseData) resolve(responseData);
                    } else throw new Error("Error al eliminar el usuario");
                })
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        reject(err.response.data?.error);
                    }
                    reject("Error al eliminar el usuario");
                });
        })
        promises.push(deleteUser)
    })

    return Promise.all(promises)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw new Error(error);
        })
};

export const getUserByIdRepository = (id) => {
    return new Promise((resolve, reject) => {
        getUserByIdApi(id)
            .then((response) => {
                if (response.status === 200) {
                    const responseData = response.data.res;
                    if (responseData) resolve(getUserByIdFormaterFromApi(responseData));
                } else throw new Error("Error al obtener el usuario");
            })
            .catch((error) => {
                console.error(error)
                if (error.response) {
                    reject(error.response.data?.msg);
                }
                reject("Error al obtener el usuario");
            })
    })
}

export const updateUserRepository = (id, userName, email, region, forcePremium, familyName) => {
    return new Promise((resolve, reject) => {
        updateUserApi(id, userName, email, region, forcePremium, familyName)
            .then((response) => {
                if (response.status === 200) {
                    const responseData = response.data.res;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al editar el usuario");
            })
            .catch((error) => {
                console.error(error)
                if (error.response) {
                    reject(error.response.data?.msg);
                }
                reject("Error al editar el usuario");
            })
    })
}
