import {getGroceryListApi} from "../../api/grocery-list";
import {getGroceryListFormatterFromApi} from "../../formatter/grocery-list";

export const getGroceryListRepository = (weekNum, year) => {
    return new Promise((resolve, reject) => {
        getGroceryListApi(weekNum, year)
            .then(response => {
                if (response.status === 200) {
                    const responseData = getGroceryListFormatterFromApi(response.data.groceries_list, weekNum, year)
                    const w = window.open('about:blank')
                    w.document.open()
                    w.document.write(responseData)
                    w.document.close()
                    resolve()
                } else {
                    reject("Error al obtener la lista de la compra");
                }
            })
            .catch(error => {
                console.error(error)
                if (error.response) {
                    reject(error.response.data?.msg);
                }
                reject("Error al obtener la lista de la compra");
            })
    })
}
