import React from "react";
import styled, {withTheme} from "styled-components/macro";
import Chart from "react-chartjs-2";
import {MoreVertical} from "react-feather";

import {Card as MuiCard, CardContent, CardHeader, IconButton,} from "@mui/material";
import {spacing} from "@mui/system";
import {alpha} from "@mui/material/styles";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
    height: 378px;
`;

function LineChart({theme}) {
    const data = (canvas) => {
        const ctx = canvas.getContext("2d");

        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
        gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

        return {
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            datasets: [
                {
                    label: "Sales ($)",
                    fill: true,
                    backgroundColor: gradient,
                    borderColor: theme.palette.secondary.main,
                    tension: 0.4,
                    data: [
                        2115, 1562, 1584, 1892, 1587, 1923, 2566, 2448, 2805, 3438, 2917,
                        3327,
                    ],
                },
                {
                    label: "Orders",
                    fill: true,
                    backgroundColor: "transparent",
                    borderColor: theme.palette.grey[500],
                    borderDash: [4, 4],
                    tension: 0.4,
                    data: [
                        958, 724, 629, 883, 915, 1214, 1476, 1212, 1554, 2128, 1466, 1827,
                    ],
                },
            ],
        };
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    color: "rgba(0,0,0,0.0)",
                },
            },
            y: {
                grid: {
                    color: "rgba(0,0,0,0.0375)",
                    fontColor: "#fff",
                },
            },
        },
    };

    return (
        <Card mb={6}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" size="large">
                        <MoreVertical/>
                    </IconButton>
                }
                title="Total revenue"
            />
            <CardContent>
                <ChartWrapper>
                    <Chart type="line" data={data} options={options}/>
                </ChartWrapper>
            </CardContent>
        </Card>
    );
}

export default withTheme(LineChart);
