import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {spacing} from "@mui/system";

import variants from "../../theme/variants";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {MONTHS, WEEK_DAYS} from "../../utils/constants";
import moment from 'moment';
import {getMonthMenuRepository} from "../../data/repository/menu";
import {useNavigate} from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Calendar() {

    function CalendarDay({day, hasMenu = false}) {

        if (day.menu) {
            return (
                <div style={{
                    display: 'flex',
                    flex: 1,
                    border: '1px solid grey',
                    height: 100,
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <span style={{width: '100%', textAlign: 'right', padding: 4}}>{day?.menu?.day}</span>
                    {hasMenu &&
                        <div style={{padding: 8}}>
                            <div style={{
                                backgroundColor: "#F7972F",
                                marginBottom: 4,
                                height: 25,
                                borderRadius: 6,
                                padding: 2,
                                paddingLeft: 8,
                                display: 'flex'
                            }}>
                                <span style={{color: 'white', fontWeight: 'bold'}}>Comida</span>
                            </div>
                            <div style={{
                                backgroundColor: "#00B4FF",
                                height: 25,
                                borderRadius: 6,
                                padding: 2,
                                paddingLeft: 8,
                                display: 'flex'
                            }}>
                                <span style={{color: 'white', fontWeight: 'bold'}}>Cena</span>
                            </div>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid grey',
                    height: 100
                }}/>
            )
        }
    }

    function CalendarRow({week}) {

        return (
            <div style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'flex'
            }}>
                {week?.map((day, index) => {
                    return (
                        <CalendarDay hasMenu={day?.menu?.hasMenu} key={index} day={day}/>
                    )
                })}
            </div>
        )
    }

    function CalendarContainer({data, isLoading, date}) {

        const navigate = useNavigate()
        let startOfMonth = moment(date).startOf('month')
        let startOfWeek = moment(startOfMonth).startOf('isoWeek')
        if (startOfWeek.year() !== startOfMonth.year()) {
            startOfWeek = moment(startOfMonth).startOf('isoWeek').add(1, 'week')
        }

        return (
            <>
                {isLoading ? <div>Cargando...</div> :
                    <div style={{
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        {data?.map((week, index) => {
                            return (
                                <div style={{display: 'flex', width: '100%'}} onClick={() => {
                                    navigate(`${moment(startOfMonth).add(index, 'weeks').week()}/${moment(startOfWeek).year()}`)
                                }}>
                                    <CalendarRow key={index} week={week}/>
                                </div>
                            )
                        })}
                    </div>
                }
            </>
        )
    }

    const [isLoading, setIsLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState(moment());
    const [monthMenuData, setMonthMenuData] = useState([]);

    const getMonthMenu = () => {
        getMonthMenuRepository(currentDate)
            .then((response) => {
                console.log(response);
                setMonthMenuData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                alert('No se han podido obtener los menús del mes')
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getMonthMenu();
    }, [currentDate]);

    return (
        <Card mb={6}>
            <CardContent p={6}>

                <Paper>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{height: 42, width: 80, display: 'flex', flexDirection: 'row'}}>
                            <div
                                onClick={() => setCurrentDate((previousState) => {
                                    return moment(previousState).subtract(1, 'month')
                                })}
                                style={{
                                    height: '100%',
                                    width: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: variants[0].palette.secondary.main
                                }}>
                                <KeyboardArrowLeftIcon style={{color: 'white'}}/>
                            </div>
                            <div
                                onClick={() => setCurrentDate((previousState) => {
                                    return moment(previousState).add(1, 'month')
                                })}
                                style={{
                                    height: '100%',
                                    width: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: variants[0].palette.secondary.main
                                }}>
                                <ChevronRightIcon style={{color: 'white'}}/>
                            </div>
                        </div>
                        <h1>{MONTHS[moment(currentDate).month()]} {moment(currentDate).year()}</h1>
                        <div/>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: 0,
                    }}>
                        {WEEK_DAYS.map(((item, index) => {
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    flex: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid grey'
                                }}>
                                    <p style={{fontSize: 14}}>{WEEK_DAYS[index].label.toUpperCase().slice(0, 3)}</p>
                                </div>
                            )
                        }))}
                    </div>

                    <CalendarContainer date={currentDate} isLoading={isLoading} data={monthMenuData}/>


                </Paper>

            </CardContent>
        </Card>
    );
}

function Menus() {

    return (
        <React.Fragment>
            <Helmet title="Menus"/>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h3" gutterBottom display="inline">
                    Menús
                </Typography>

                <Typography variant="h5" gutterBottom display="inline">
                    Aquí puedes gestionar todos los menús
                </Typography>
            </div>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Calendar/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Menus;
