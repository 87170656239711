import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {Button, Card as MuiCard, Divider as MuiDivider, Grid, Paper as MuiPaper, Typography,} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {spacing} from "@mui/system";
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from "react-router-dom";
import Searchbar from "../../components/unit/Searchbar";
import {deleteUsersRepository, getUsersListRepository} from "../../data/repository/user";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const columns = [
    {
        field: "familyName",
        headerName: "Nombre família",
        width: 150,
    },
    {
        field: "email",
        headerName: "Email",
        width: 300,
    },
    {
        field: "groupMembers",
        headerName: "Miembros",
        type: "number",
        width: 120,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: "subscribed",
        headerName: "Suscripción activa",
        type: "boolean",
        width: 160,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: "registerDate",
        headerName: "Fecha de registro",
        type: "date",
        width: 200,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
            return (
                <span>{params.value ? moment(params.value).format("DD/MM/YYYY HH:mm") : ''}</span>
            )
        }
    },
    {
        field: "generatedMenus",
        headerName: "Menús generados",
        type: "number",
        width: 160,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: "actions",
        headerName: "Acciones",
        minWidth: 200,
        flex: 1,
        renderCell: params => {
            return (
                <div style={{width: '100%', display: 'flex'}}>
                    <button style={{border: "none", backgroundColor: "transparent"}}><EditIcon/></button>
                </div>
            )

        }
    },
];

function DataGridUsers() {

    const navigate = useNavigate()

    const [usersList, setUsersList] = useState([]);
    const [filteredData, setFilteredData] = useState(usersList);
    const [idRowsSelected, setIdRowSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');


    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        handleFilter()
    }, [search])

    const getList = () => {
        getUsersListRepository()
            .then((response) => {
                console.log(response)
                setUsersList(response)
                setFilteredData(response)
                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }


    const handleDelete = () => {
        if (window.confirm('¿Seguro que quieres eliminar estos usuarios? Este cambio no se puede deshacer')) {
            deleteUsersRepository(idRowsSelected)
                .then((response) => {
                    console.log(response)
                    alert('Usuarios eliminados correctamente')
                })
                .catch((error) => {
                    console.error(error)
                    alert('No se ha podido eliminar los usuarios')
                })
        }
    };

    const handleFilter = () => {
        let newUserList = usersList.filter((item) => item.user?.toLowerCase().includes(search.toLowerCase()) || item.email?.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(newUserList);
    }

    return (
        <>
            <div style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 12,
                paddingBottom: 24
            }}>
                <Grid style={{maxWidth: '50%', display: "flex", alignItems: "center", flex: 1}}>
                    <Searchbar placeholder="Buscar usuario" name="searchUser"
                               onChange={(event) => setSearch(event.target.value)}/>
                </Grid>
                <div style={{maxWidth: 300}}>
                    {(idRowsSelected.length > 0) &&
                        <Button
                            type="submit"
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            Eliminar usuario
                        </Button>}
                </div>
            </div>
            <Card mb={6}>
                <Paper>
                    <div style={{padding: 20}}>
                        <DataGrid
                            onSelectionModelChange={(selected) => setIdRowSelected(selected)}
                            rowsPerPageOptions={[25, 50, 100]}
                            autoHeight
                            rows={filteredData}
                            columns={columns}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'user', sort: 'asc' }],
                                },
                            }}
                            loading={isLoading}
                            checkboxSelection
                            density={'compact'}
                            onRowClick={(params) => {
                                navigate(`${params.row.id}`, {state: {name: params.row.user}})
                            }}
                        />
                    </div>
                </Paper>
            </Card>
        </>
    );
}

function Users() {
    return (
        <React.Fragment>
            <Helmet title="Usuarios"/>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h3" gutterBottom display="inline">
                    Gestión de usuarios
                </Typography>

                <Typography variant="h5" gutterBottom display="inline">
                    Aquí puedes controlar todos tus clientes
                </Typography>
            </div>

            <Divider my={6}/>

            <DataGridUsers/>

        </React.Fragment>
    );
}

export default Users;
