import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Button,
    Card as MuiCard,
    Divider as MuiDivider,
    Grid,
    Paper,
    TextField as MuiTextField,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";
import {DataGrid} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {deleteRecipesRepository, getRecipesRepository} from "../../data/repository/recipe";
import {useNavigate} from "react-router-dom";
import Searchbar from "../../components/unit/Searchbar";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


const columns = [
    {
        field: "name",
        headerName: "Receta",
        width: 200,
        flex: 1,
    },
    {
        field: "familyName",
        headerName: "Fam. de ingr.",
        width: 150,
    },
    {
        field: "sustitutiveRecipe",
        headerName: "Receta sustitutiva",
        width: 150,
        renderCell: (rowData) => {
            return rowData.row.sustitutiveRecipe.name ? rowData.row.sustitutiveRecipe.name : "-";
        },
    },
    {
        field: "allergens",
        headerName: "Alérgenos",
        width: 100,
    },
    {
        field: "cookingTime",
        headerName: "Tiempo",
        width: 80,
    },
    {
        field: "actions",
        headerName: "Acciones",
        headerAlign: 'center',
        width: 100,
        renderCell: params => {
            return (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <button onClick={(e) => {
                        e.stopPropagation()
                        window.location = `recipes/${params.id}`
                    }} style={{border: "none", backgroundColor: "transparent", cursor: 'pointer'}}><EditIcon/></button>
                    <button onClick={(e) => {
                        e.stopPropagation()
                        if (window.reactNavigate) {
                            window.reactNavigate(`dashboard/recipes/new`, {state: {id: params.id}})
                        }
                    }} style={{border: "none", backgroundColor: "transparent", cursor: 'pointer'}}><ContentCopyIcon/></button>
                </div>
            )

        }
    },

];

function DataGridRecipes() {

    const navigate = useNavigate()

    const [recipesList, setRecipesList] = useState([]);
    const [filteredData, setFilteredData] = useState(recipesList);
    const [idRowsSelected, setIdRowSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');

    const navigation = useNavigate()

    const getList = () => {
        getRecipesRepository()
            .then((response) => {
                console.log(response)
                setRecipesList(response)
                setFilteredData(response)
                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const TextField = styled(MuiTextField)(spacing);

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        handleFilter()
    }, [search])

    const handleDelete = () => {
        if (window.confirm('¿Seguro que quieres eliminar estas recetas? Este cambio no se puede deshacer')) {
            deleteRecipesRepository(idRowsSelected)
                .then((response) => {
                    console.log(response)
                    getList()
                    alert('Recetas eliminadas correctamente')
                })
                .catch((error) => {
                    console.error(error)
                    alert('No se ha podido eliminar las recetas')
                })
        }
    };

    const handlePrint = () => {

        let recipeList = JSON.stringify(idRowsSelected);
        sessionStorage.setItem('print-recipes', recipeList);

        window.open('/dashboard/print','_blank')

    }

    const handleFilter = () => {
        let filteredList = recipesList.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.familyName.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(filteredList);
    }

    return (
        <>
            <div style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 12,
                paddingBottom: 24
            }}>
                <Grid style={{maxWidth: '50%', display: "flex", alignItems: "center", flex: 1}}>
                    <Searchbar placeholder="Buscar receta" name="searchRecipe"
                               onChange={(event) => setSearch(event.target.value)}/>
                </Grid>

                <div style={{display: "flex", gap: 12, flex: 1, justifyContent: "flex-end"}}>

                    <div style={{maxWidth: 300}}>
                        {(idRowsSelected.length > 0) &&
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                onClick={handlePrint}
                            >
                                Imprimir listado
                            </Button>}
                    </div>

                    {(idRowsSelected.length > 0) &&
                        <Button
                            type="submit"
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            Eliminar recetas
                        </Button>}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("new", {state: {name: "Nueva receta"}})}
                    >
                        Añadir receta
                    </Button>
                </div>

            </div>
            <Card mb={6}>
                <Paper>
                    <div style={{padding: 20}}>
                        <DataGrid
                            onSelectionModelChange={(selected) => setIdRowSelected(selected)}
                            rowsPerPageOptions={[25, 50, 100]}
                            autoHeight
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                            }}
                            rows={filteredData}
                            columns={columns}
                            loading={isLoading}
                            checkboxSelection
                            density={'compact'}
                            onRowClick={(params) => navigation(`${params.row.id}`)}
                        />
                    </div>
                </Paper>
            </Card>
        </>
    );
}

function Recipes() {
    return (
        <React.Fragment>
            <Helmet title="Recetas"/>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h3" gutterBottom display="inline">
                    Tus recetas
                </Typography>

                <Typography variant="h5" gutterBottom display="inline">
                    Aquí puedes controlar todas tus recetas
                </Typography>
            </div>

            <Divider my={6}/>

            <DataGridRecipes/>

        </React.Fragment>
    );
}

export default Recipes;
