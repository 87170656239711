import React from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Link,
    Paper as MuiPaper,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);



function DataGridUsers() {
    return (
        <Card mb={6}>
            <CardContent pb={1}>
                <Typography variant="h6" gutterBottom>
                    Revisa tus facturas a partir de estos enlaces:
                    <Link
                        href="https://play.google.com/console"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{marginRight: 20, marginLeft: 20}}
                    >
                        Facturas Android
                    </Link>
                    <Link
                        href="https://developer.apple.com"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{marginRight: 20}}
                    >
                        Facturas iOS
                    </Link>
                    <Link
                        href="https://app.adapty.io/login"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Adapty
                    </Link>
                </Typography>

            </CardContent>
        </Card>
    );
}

function Billing() {
    return (
        <React.Fragment>
            <Helmet title="Facturas"/>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h3" gutterBottom display="inline">
                    Tus facturas
                </Typography>

                <Typography variant="h5" gutterBottom display="inline">
                    Todos tus documentos en un solo lugar
                </Typography>
            </div>

            <Divider my={6}/>

            <DataGridUsers/>
        </React.Fragment>
    );
}

export default Billing;
