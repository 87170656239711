import {recoverPasswordApi, sendEmailApi} from "../../api/recover-password";

export const recoverPasswordRepository = (recoveryToken, newPassword) => {
    return new Promise((resolve, reject) => {
        recoverPasswordApi(recoveryToken, newPassword)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al cambiar la contraseña");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error al cambiar la contraseña");
            });
    })
};

export const sendEmailRepository = (email) => {
    return new Promise((resolve, reject) => {
        sendEmailApi(email)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error");
            });
    })
}
