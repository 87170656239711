import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Outlet} from "react-router-dom";

import {Box, CssBaseline, Paper as MuiPaper} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {spacing} from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import NavbarSimple from "../components/navbar/NavbarSimple";

const drawerWidth = 258;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up("md")} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: hidden;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.palette.background.default};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

const Dashboard = ({children}) => {

    const [mobileOpen, setMobileOpen] = useState(false);
    const [labels, setLabels] = useState(dashboardItems)

    useEffect(() => {
        setLabels((prevState) => {
            console.log(prevState);
            console.log(dashboardItems)
            let newLabels = dashboardItems;
            if (!localStorage.getItem('PERM_GET_BILLING')) {
                newLabels[0].pages.splice(5, 5);
            }
            if (!localStorage.getItem('PERM_GET_MENUS')) {
                newLabels[0].pages.splice(4, 4);
            }
            if (!localStorage.getItem('PERM_GET_RECIPES')) {
                newLabels[0].pages.splice(3, 3);
            }
            if (!localStorage.getItem('PERM_GET_INGREDIENTS')) {
                newLabels[0].pages.splice(2, 2);
            }
            if (!localStorage.getItem('PERM_GET_APP_USERS')) {
                newLabels[0].pages.splice(1, 1);
            }
            return newLabels
        })
    }, [])


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <Root>
            <CssBaseline/>
            <GlobalStyle/>
            <Drawer>
                <Box sx={{display: {xs: "block", lg: "none"}}}>
                    <Sidebar
                        PaperProps={{style: {width: drawerWidth}}}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        items={labels}
                    />
                </Box>
                <Box sx={{display: {xs: "none", md: "block"}}}>
                    <Sidebar
                        PaperProps={{style: {width: drawerWidth}}}
                        items={labels}
                    />
                </Box>
            </Drawer>
            <AppContent>
                <Box sx={{display: {xs: "block", md: "none", lg: "none"}}}>
                    <NavbarSimple onDrawerToggle={handleDrawerToggle}/>
                </Box>
                <MainContent p={isLgUp ? 12 : 5}>
                    {children}
                    <Outlet/>
                </MainContent>
                {/*<Footer />*/}
            </AppContent>
            {/*<Settings />*/}
        </Root>
    );
};

export default Dashboard;
