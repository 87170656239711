import moment from "moment";
import _ from 'lodash'

export const getMonthMenuFormatterFromApi = (data, date) => {

    let startDayOfTheMonth = moment(date).startOf('month').weekday();
    let daysInMonth = moment(date).daysInMonth();
    let currentYear = moment(date).year();
    let currentMonth = moment(date).month() + 1;

    let res = []

    let addedDays = 0;
    let monthDay = 0;

    const handleHasMenu = (day) => {

        return data?.some((item) => {
            const targetDate = moment(`${currentYear}-${currentMonth}-${day}`, 'YYYY-MM-DD');
            return item.meals?.some((meal) => {
                const itemDate = moment(meal.date);
                return targetDate.startOf('day').isSame(itemDate.startOf('day'))
            })
        })
    }

    for (let i = 0; monthDay < daysInMonth; i++) {
        res.push(Array(7));
        for (let j = 0; j < res[i].length; j++) {
            if (i === 0 && j < startDayOfTheMonth) {
                res[i][j] = {};
            } else if (monthDay >= daysInMonth) {
                res[i][j] = {};
            } else {
                monthDay++;
                res[i][j] = {
                    menu: {
                        day: monthDay,
                        hasMenu: handleHasMenu(monthDay)
                    }
                };
            }
            addedDays++;
        }
    }
    return res
}

export const getRecipesWeekMenuFormatter = (data) => {

    let newArray = []

    data.res.map((recipe) => {
        let newObject = {
            label: recipe.name,
            id: recipe.id
        }
        newArray.push(newObject)
    })

    return newArray.sort((a, b) => a.label.localeCompare(b.label))

};

export const getWeekMenuFormatterFromApi = (data) => {

    let newArray = []
    let day = [
        {
            mealName: "Comida",
            selectedRecipes: [
                {
                    dishType: "Primero",
                    recipeIds: [null, null, null]
                },
                {
                    dishType: "Segundo",
                    recipeIds: [null, null, null]
                },
                {
                    dishType: "Postre",
                    recipeIds: [null, null, null]
                },
                {
                    dishType: "Plato único",
                    recipeIds: [null]
                }
            ]
        },
        {
            mealName: "Cena",
            selectedRecipes: [
                {
                    dishType: "Primero",
                    recipeIds: [null, null, null]
                },
                {
                    dishType: "Segundo",
                    recipeIds: [null, null, null]
                },
                {
                    dishType: "Postre",
                    recipeIds: [null, null, null]
                },
                {
                    dishType: "Plato único",
                    recipeIds: [null]
                }
            ]
        }
    ]

    for (let i = 0; i < 7; i++){

        const item = _.cloneDeep(data[i]);

        if (!item) {
            newArray.push(_.cloneDeep(day));
            continue
        }
        let PrimeroComida = item.meals[0].dishes.filter((dish) => dish.dishType === 'Primero').map((dish) => (dish.recipeId))
        let SegundoComida = item.meals[0].dishes.filter((dish) => dish.dishType === 'Segundo').map((dish) => (dish.recipeId))
        let PostreComida = item.meals[0].dishes.filter((dish) => dish.dishType === 'Postre').map((dish) => (dish.recipeId))
        let PlatoUnicoComida = item.meals[0].dishes.filter((dish) => dish.dishType === 'Plato único').map((dish) => (dish.recipeId))
        let PrimeroCena = item.meals[1].dishes.filter((dish) => dish.dishType === 'Primero').map((dish) => (dish.recipeId))
        let SegundoCena = item.meals[1].dishes.filter((dish) => dish.dishType === 'Segundo').map((dish) => (dish.recipeId))
        let PostreCena = item.meals[1].dishes.filter((dish) => dish.dishType === 'Postre').map((dish) => (dish.recipeId))
        let PlatoUnicoCena = item.meals[1].dishes.filter((dish) => dish.dishType === 'Plato único').map((dish) => (dish.recipeId))

        let newDay = _.cloneDeep(day);

        if (PrimeroComida.length !== 3) PrimeroComida = [null, null, null];
        if (SegundoComida.length !== 3) SegundoComida = [null, null, null];
        if (PostreComida.length !== 3) PostreComida = [null, null, null];
        if (PlatoUnicoComida.length !== 1) PlatoUnicoComida = [null];
        if (PrimeroCena.length !== 3) PrimeroCena = [null, null, null];
        if (SegundoCena.length !== 3) SegundoCena = [null, null, null];
        if (PostreCena.length !== 3) PostreCena = [null, null, null];
        if (PlatoUnicoCena.length !== 1) PlatoUnicoCena = [null];

        newDay[0].selectedRecipes[0].recipeIds = _.cloneDeep(PrimeroComida);
        newDay[0].selectedRecipes[1].recipeIds = _.cloneDeep(SegundoComida);
        newDay[0].selectedRecipes[2].recipeIds = _.cloneDeep(PostreComida);
        newDay[0].selectedRecipes[3].recipeIds = _.cloneDeep(PlatoUnicoComida);
        newDay[1].selectedRecipes[0].recipeIds = _.cloneDeep(PrimeroCena);
        newDay[1].selectedRecipes[1].recipeIds = _.cloneDeep(SegundoCena);
        newDay[1].selectedRecipes[2].recipeIds = _.cloneDeep(PostreCena);
        newDay[1].selectedRecipes[3].recipeIds = _.cloneDeep(PlatoUnicoCena);
        newArray.push(newDay);
    }


    return newArray

}

export const updateWeekMenuFormatterToApi = (data) => {
    let dataToApi = []

    data.map(meal => {
        dataToApi.push({meals: meal})
    })

    console.log(dataToApi)
    return dataToApi;
}
