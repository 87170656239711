import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Alert,
    Breadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Grid,
    Link,
    Paper,
    Snackbar,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import ActivityButton from "../../components/unit/ActivityButton";
import {
    deleteRecipesRepository,
    getRecipeRepository,
    saveRecipeRepository,
    uploadRecipeImageRepository
} from "../../data/repository/recipe";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import ReactQuillEditor from "../../components/unit/ReactQuillEditor";
import {DataGrid, useGridApiContext} from "@mui/x-data-grid";
import _ from "lodash";
import {getCookingTypesRepository, getIngredientsListRepository} from "../../data/repository/ingredients";
import {getIngredientsNameFormatterFromApi} from "../../data/formatter/recipe";
import {getCookingTypesForIngredientApi} from "../../data/api/ingredients";
import AutocompleteSelect from "../../components/unit/AutocompleteSelect";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const emptyRow = {
    id: 1,
    quantity: 0,
    cookingTypeId: 1,
    isMainIngredient: false
}

function renderCookingTypeEditInputCell(params) {
    return <CookingTypeEditInputCell {...params} />;
}

function CookingTypeEditInputCell(props) {
    const {id, value, api, field, row} = props;

    const [values, setValues] = useState([{value: 0, label: "Cargando"}])

    useEffect(() => {
        fetchCookingTypesForIngredient(row.id);
    }, [row.id])

    const fetchCookingTypesForIngredient = (ingredientId) => {
        getCookingTypesForIngredientApi(ingredientId)
            .then(r => {
                if (r?.data?.res) {
                    const newValues = [];
                    r?.data?.res.forEach(cType => {
                        newValues.push({
                            value: cType.id,
                            label: cType.type
                        })
                    })
                    setValues(newValues);
                } else {
                    setValues([{value: 0, label: "Error. El ingrediente tiene tipos de cocción?"}])
                }
            })
            .catch(err => {
                console.error("Error obteniendo tipos de cocción del ingrediente", err)
                setValues([{value: 0, label: "Error. El ingrediente tiene tipos de cocción?"}])
            })
    }

    const handleChange = async (event) => {
        if (event.target.value === 0) return;
        api.setEditCellValue({id, field, value: Number(event.target.value)}, event);
        // Check if the event is not from the keyboard
        // https://github.com/facebook/react/issues/7407
        if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
            // Wait for the validation to run
            const isValid = await api.commitCellChange({id, field});
            if (isValid) {
                api.setCellMode(id, field, 'view');
            }
        }
    };

    const handleRef = (element) => {
        if (element) {
            element.querySelector(`input[value="${value}"]`).focus();
        }
    };

    return (

        <CustomTextField ref={handleRef} type={CustomTextFieldTypes.SELECT} options={{
            label: "",
            value: value,
            onChange: handleChange,
            dropdownOptions: values,
            name: 'cookingTypeForIngredient'
        }}/>

    );
}

function CustomEditComponent(params) {
    const {id, value, field} = params;
    console.info("CustomEditComponent", params)
    const apiRef = useGridApiContext();

    const handleValueChange = (event) => {
        if (!event?.value && event?.value !== 0) return;
        const newValue = event.value; // The new value entered by the user
        apiRef.current.setEditCellValue({id, field, value: newValue});
    };

    return <AutocompleteSelect
        label={""}
        value={params.row.ingredientInfo}
        options={params.colDef.valueOptions}
        onChange={handleValueChange}
    />

}

function RecipesDetail() {

    const navigate = useNavigate();
    const {state, pathname} = useLocation();
    const {id} = useParams();
    const recipeToBeDuplicated = typeof state?.id === "number" && pathname.includes("new")

    const [recipeName, setRecipeName] = useState(state?.name);

    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarText, setSnackbarText] = useState("");

    const [columns, setColumns] = useState([
        {
            field: 'id',
            headerName: "Ingrediente",
            // type: 'singleSelect',
            valueOptions: [],
            minWidth: 250,
            flex: 1,
            valueFormatter: (params) => {
                const col = params.api.getColumn('id');
                let a = col?.valueOptions?.find((option) => option.value === params.id)?.label
                if (!a) return "";
                return a;
            },
            editable: true,
            renderEditCell: (params) => {
                return <CustomEditComponent {...params} />
            }
        },
        {
            field: "quantity",
            headerName: "Cantidad (en gr.)",
            type: 'number',
            width: 100,
            editable: true,
        },
        {
            field: "integerUnits",
            headerName: "Unidades",
            description: "En caso que el ingrediente se venda por unidades, introducir la cantidad en unidades. Campo opcional",
            type: 'number',
            width: 100,
            editable: true,
            sortable: false
        },
        {
            field: "cookingTypeId",
            headerName: "Cocción",
            minWidth: 250,
            renderEditCell: renderCookingTypeEditInputCell,
            type: 'singleSelect',
            valueOptions: [],
            editable: true,
            valueFormatter: (params) => {
                const col = params.api.getColumn('cookingTypeId');
                return col?.valueOptions?.find((option) => option.id === params.value)?.label
            },
        },
        {
            field: "isMainIngredient",
            headerName: "Ingr. principal",
            type: 'boolean',
            width: 120,
            editable: true,
        },
    ]);

    const [rows, setRows] = useState([]);
    const [idsRowsSelected, setIdsRowsSelected] = useState([]);

    const [recipe, setRecipe] = useState();
    const [cookingTypes, setCookingTypes] = useState([]);

    const [inputs, setInputs] = useState({
        name: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nombre de la receta",
                value: "",
                onChange: handleChangeInputs
            }
        },
        cookingTime: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Tiempo de preparación (min.)",
                value: "",
                onChange: handleChangeInputs,
            }
        },
        sustitutiveRecipe: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Receta sustitutiva",
                value: "",
                onChange: handleChangeAutocompleteInput,
                dropdownOptions: [],
                openNewTabOnSelect: true
            }
        },
        shortDescription: {
            type: CustomTextFieldTypes.LARGE_INPUT,
            options: {
                label: "Descripción corta (max. 140 caracteres)",
                value: "",
                onChange: handleChangeInputs
            }
        },
        image: {
            type: CustomTextFieldTypes.IMAGE,
            options: {
                label: "Imagen",
                subtitle: "Usa una imagen de mínimo 600x400 y con formato jpg.",
                value: "",
                onChange: handleUploadFile
            }
        },
    })

    useEffect(() => {
        if (id !== "new" || recipeToBeDuplicated) fetchAllData()
        else {
            const cookingTypes = getCookingTypesRepository()


            const ingredients = getIngredientsListRepository()

            Promise.all([cookingTypes, ingredients])
                .then((data) => {
                    updateData({cookingTypes: data[0], ingredients: getIngredientsNameFormatterFromApi(data[1])});
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setSnackbarSeverity("error");
                    setSnackbarText("Ha habido un error obteniendo tipos de cocción o ingredientes");
                    setSnackbar(true);
                    setIsLoading(false)
                })
        }
    }, [id]);

    function handleUploadFile(event) {
        const formData = new FormData();
        if (!event?.target?.files[0]) return;
        formData.append("image", event?.target?.files[0], event?.target?.files[0]?.name);
        uploadRecipeImageRepository(id, formData)
            .then((res) => {
                console.log(res);
                setSnackbarSeverity("success");
                setSnackbarText("Imagen subida correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                handleChangeInputs({
                    target: {
                        name: 'image',
                        value: res.url
                    }
                })

            })
            .catch((err) => {
                setSnackbarSeverity("error");
                setSnackbarText(err);
                setSnackbar(true);
                setIsUpdating(false);
            })
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        if (name === "shortDescription" && value.length > 140) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function handleChangeAutocompleteInput(event) {
        const value = event.target.options.find(item => item.value === event.target.value?.value);
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        if (!!value) {
            newInputs[name].options.value = value;
        } else {
            newInputs[name].options.value = "";
        }
        setInputs(newInputs)
    }

    function updateTypesOnTable(newCookingTypes, newIngredients) {
        const newColumns = _.cloneDeep(columns);
        for (const col of newColumns) {
            if (col.field === 'cookingTypeId') {
                col.valueOptions = newCookingTypes
            }
            if (col.field === 'id') {
                col.valueOptions = newIngredients
            }
        }
        setColumns(newColumns);
    }

    const fetchAllData = () => {
        const recipeId = recipeToBeDuplicated ? state.id : id
        getRecipeRepository(recipeId)
            .then((data) => {
                console.log("data => ", data)
                updateData({recipe: data[0], allRecipes: data[1], cookingTypes: data[2], ingredients: data[3]})
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Ha habido un error obteniendo la receta");
                setSnackbar(true);
                setIsLoading(false)
            })

    }

    const updateData = ({recipe, allRecipes, cookingTypes, ingredients}) => {
        if (allRecipes) {
            const newInputs = {...inputs};
            Object.keys(newInputs).forEach((key) => {
                if (recipe) {
                    newInputs[key].options.value = recipe[key];
                }
                if (allRecipes && key === "sustitutiveRecipe") {
                    newInputs[key].options.dropdownOptions = allRecipes;
                }
            })
            setInputs(newInputs)
        }

        if (recipe) {
            setRecipeName(recipe.name);
            setRecipe(recipe)
            setRows(recipe.ingredients)
        }
        if (cookingTypes && ingredients) {
            setCookingTypes(cookingTypes);
            updateTypesOnTable(cookingTypes, ingredients);
        }
    }

    const addNewRow = () => {
        const maxId = Math.max.apply(Math, rows.map(function (o) {
            return o.id;
        }))

        const nextId = rows.length > 0 ? maxId + 1 : 0;

        setRows([...rows, {...emptyRow, id: nextId}]);
    }

    const deleteRows = () => {
        const res = window.confirm("¿Seguro que quieres borrar los ingredientes seleccionados?")
        if (!res) return;
        let newRows = [];
        for (let row of rows) {
            if (!idsRowsSelected.some((id) => id === row.id)) {
                newRows.push(row);
            }
        }
        setRows(newRows);
    }

    const deleteRecipe = () => {
        const res = window.confirm("¿Seguro que quieres borrar el la receta? Esta operación no es reversible y sólo se ejecutará si la receta no tiene asociaciones existentes.")
        if (!res) return;
        deleteRecipesRepository([id])
            .then(() => {
                setSnackbarSeverity("success");
                setSnackbarText("Receta eliminada correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                navigate(`/dashboard/recipes`);

            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Ha habido un error borrando la receta");
                setSnackbar(true);
                setIsUpdating(false);
            })
    }

    const save = () => {
        const formatHtml = (html) => {
            var tab = "\t";
            var result = "";
            var indent = "";

            html?.split(/>\s*</).forEach(function (element) {
                if (element.match(/^\/\w/)) {
                    indent = indent.substring(tab.length);
                }

                result += indent + "<" + element + ">\r\n";

                if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith("input")) {
                    indent += tab;
                }
            });

            return result.substring(1, result.length - 3);
        };
        setIsUpdating(true);
        const recipeId = id === "new" ? null : id
        let recipeSave = {...recipe};
        Object.keys(inputs).forEach((key) => {
            recipeSave[key] = inputs[key].options.value;
        })
        recipeSave.detail = formatHtml(recipeSave.detail);
        console.log("[RecipesDetail] recipeSave ", recipeSave);
        recipeSave.ingredients = rows;

        console.log("recipe to save => ", recipeSave)
        saveRecipeRepository(recipeId, recipeSave, recipeToBeDuplicated)
            .then((recipe) => {
                setSnackbarSeverity("success");
                setSnackbarText("Receta guardada correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                if (id === "new") {
                    navigate(`/dashboard/recipes/${recipe.id}`);
                } else {
                    fetchAllData()
                }
            })
            .catch((err) => {
                setSnackbarSeverity("error");
                setSnackbarText(err);
                setSnackbar(true);
                setIsUpdating(false);
            })
    }


    const handleRowEdited = (row) => {
        let index = rows.findIndex(r => r.id === row.id)
        rows[index][row.field] = row.value
        setRows([...rows])
    }

    return (
        <React.Fragment>
            <Helmet title="Recetas"/>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Añadir/editar receta
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                        <Link component={NavLink} to="/dashboard">
                            CMS
                        </Link>
                        <Link component={NavLink} to="/dashboard/recipes">
                            Recetas
                        </Link>
                        <Typography>{recipeName}</Typography>
                    </Breadcrumbs>
                </div>

                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 12}}>
                    {id !== "new" && <ActivityButton title={"Borrar"} variant={'outlined'} onClick={deleteRecipe}/>}
                    <ActivityButton title={"Guardar"} isUpdating={isUpdating} onClick={save}/>
                </div>

            </div>

            <Divider my={3}/>
            <Grid container spacing={6}>
                <Grid item md={4} style={{display: "flex", flexDirection: "column", minWidth: 224, gap: 12}}>
                    {Object.keys(inputs).map((key, index) => {
                            if (index > 2) return <></>;
                            if (id === "new" && index > 1) return <></>;
                            const input = inputs[key];
                            return (
                                <Card style={{padding: 24}} key={index}>
                                    <CustomTextField type={input.type} options={{...input.options, name: key}}/>
                                </Card>
                            )
                        }
                    )}
                </Grid>
                <Grid item md={4} style={{minWidth: 224}} spacing={6}>
                    <Card style={{padding: 24}}>
                        <CustomTextField type={CustomTextFieldTypes.LARGE_INPUT}
                                         options={{...inputs['shortDescription']?.options, name: "shortDescription"}}/>
                    </Card>
                </Grid>
                {id !== "new" && <Grid item md={4} style={{minWidth: 224}} spacing={6}>
                    <Card style={{padding: 24}}>
                        <CustomTextField type={CustomTextFieldTypes.IMAGE}
                                         options={{...inputs['image']?.options, name: "image"}}/>
                    </Card>
                </Grid>}
            </Grid>

            <Card style={{marginTop: 24}}>
                <ReactQuillEditor
                    titol="Detalles"
                    value={recipe?.detail}
                    handleChange={(event) => {
                        setRecipe({
                            ...recipe,
                            detail: event.target.value
                        })
                    }}
                    name="detail"
                />
            </Card>

            <div style={{flex: 1, marginTop: 30}}>
                <Typography variant="h4" style={{marginBottom: 4}}>
                    Ingredientes
                </Typography>
                <Typography variant="p">
                    Añade los ingredientes de la receta, especificando la cantidad <b>para 1 persona adulta</b> y el
                    tipo de cocción.</Typography>
            </div>
            <Divider my={3}/>

            <Card>
                <Paper>
                    <div style={{padding: 20}}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            gap: 8,
                        }}>
                            {idsRowsSelected.length > 0 &&
                                <ActivityButton title={"Borrar"} variant={'outlined'}
                                                color={'error'} onClick={deleteRows}/>}
                            <ActivityButton title={"Añadir fila"} variant={'outlined'} onClick={addNewRow}/>
                        </div>
                        <DataGrid
                            style={{marginTop: 20}}
                            onSelectionModelChange={(selected) => setIdsRowsSelected(selected)}
                            rowsPerPageOptions={[25, 50, 100]}
                            autoHeight
                            rows={rows}
                            columns={columns}
                            loading={isLoading}
                            disableSelectionOnClick
                            onCellEditCommit={handleRowEdited}
                            checkboxSelection
                        />
                    </div>
                </Paper>
            </Card>

            <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default RecipesDetail;

