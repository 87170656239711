import {loginUserApi} from "../../api/auth";
import {loginUserFormatterFromApi} from "../../formatter/auth";

export const loginRepository = (email, password) => {
    return new Promise((resolve, reject) => {
        loginUserApi(email, password)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(loginUserFormatterFromApi(responseData));
                } else throw new Error("Error de login");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error en el login. Asegúrese que los datos son correctos.");
            });
    })
};
