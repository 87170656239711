import React from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet-async";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Card as MuiCard,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia as MuiCardMedia,
    Divider as MuiDivider,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

function SimpleCard() {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    Word of the Day
                </Typography>
                <Typography variant="h5" component="h2">
                    be•nev•o•lent
                </Typography>
                <Typography color="textSecondary">adjective</Typography>
                <Typography component="p">
                    well meaning and kindly.
                    <br/>
                    {'"a benevolent smile"'}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
}

function MediaCard() {
    return (
        <Card mb={6}>
            <CardActionArea>
                <CardMedia
                    image="/static/img/unsplash/unsplash-1.jpg"
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Lizard
                    </Typography>
                    <Typography component="p">
                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem
                        quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam
                        quam nunc, blandit vel, luctus pulvinar.
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary">
                    Share
                </Button>
                <Button size="small" color="primary">
                    Learn More
                </Button>
            </CardActions>
        </Card>
    );
}

function Cards() {
    return (
        <React.Fragment>
            <Helmet title="Cards"/>
            <Typography variant="h3" gutterBottom display="inline">
                Cards
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/">
                    Components
                </Link>
                <Typography>Cards</Typography>
            </Breadcrumbs>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <MediaCard/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SimpleCard/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Cards;
