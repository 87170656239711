import {getMonthMenuApi, getWeekMenuApi, updateweekMenuApi} from "../../api/menu";
import moment from "moment";
import {
    getMonthMenuFormatterFromApi,
    getRecipesWeekMenuFormatter,
    getWeekMenuFormatterFromApi,
    updateWeekMenuFormatterToApi
} from "../../formatter/menu";
import {getRecipesApi} from "../../api/recipe";


export const getMonthMenuRepository = (date) => {

    let month = moment(date).month() + 1;
    let year = moment(date).year();

    return new Promise((resolve, reject) => {
        getMonthMenuApi(month, year)
            .then((response) => {
                if (response.status === 200) {
                    resolve(getMonthMenuFormatterFromApi(response.data.res, date))
                } else reject(response);
            })
            .catch((error) => {
                console.error(error.response);
                if (error?.response?.data?.msg) {
                    reject(error?.response?.data?.msg)
                } else {
                    reject("Error indefinido.")
                }
            })
    })
}


export const getWeekMenuRepository = (date) => {

    let week = moment(date).week();
    let year = moment(date).year();

    const getWeekMenu = new Promise((resolve, reject) => {
        getWeekMenuApi(week, year)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    if (response.data.res.length) {
                        resolve(getWeekMenuFormatterFromApi(response.data.res))
                    } else {
                        if (localStorage.getItem(`${week}-${year}-saved-menu`)) {
                            resolve(JSON.parse(localStorage.getItem(`${week}-${year}-saved-menu`)))
                        } else {
                            resolve(getWeekMenuFormatterFromApi({res: []}))
                        }
                    }
                } else reject(response);

            })
            .catch((error) => {
                console.error(error.response);
                if (error?.response?.data?.msg) {
                    reject(error?.response?.data?.msg)
                } else {
                    reject("Error indefinido.")
                }
            })
    })

    const getRecipes = new Promise((resolve, reject) => {
        getRecipesApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(getRecipesWeekMenuFormatter(responseData));
                } else throw new Error("Error al obtener las recetas");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    reject(err.response.data?.msg);
                }
                reject("Error al obtener las recetas");
            });
    })



    return Promise.all([getWeekMenu, getRecipes])
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw new Error(error)
        })

}

export const updateWeekMenuRepository = (date, data) => {

    let week = moment(date).week();
    let year = moment(date).year();

    return new Promise((resolve, reject) => {
        updateweekMenuApi(week, year, updateWeekMenuFormatterToApi(data))
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error al actualizar el menú");
            })
            .catch(err => {
                console.error(err)
                if (err.response) {

                    reject(err.response.data?.msg);
                }
                reject("Error al actualizar el menú");
            });
    })
}
