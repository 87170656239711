import React, {useState} from "react";
import styled from "styled-components/macro";
import {Button, Paper, TextField as MuiTextField, Typography} from "@mui/material";
import {spacing} from "@mui/system";
import {ReactComponent as Logo} from "../../vendor/logo_black.svg";
import {Helmet} from "react-helmet-async";
import {sendEmailRepository} from "../../data/repository/recover-password";

const RecoverEmail = () => {

    const TextField = styled(MuiTextField)(spacing);

    const [sentEmail, setSentEmail] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const Brand = styled(Logo)`
        fill: ${(props) => props.theme.palette.primary.main};
        width: 174px;
        height: 115px;
        text-align: center;
        margin: 0 auto ${(props) => props.theme.spacing(5)};
    `;

    const Wrapper = styled(Paper)`
        padding: ${(props) => props.theme.spacing(6)};

        ${(props) => props.theme.breakpoints.up("md")} {
            padding: ${(props) => props.theme.spacing(10)};
        }
    `;

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target[0].value;
        setIsUpdating(true);
        sendEmailRepository(email)
            .then((response) => {
                setSentEmail(true);
                setIsUpdating(false);
            })
            .catch((error) => {
                console.error(error);
                setIsUpdating(false);
                alert('No hemos podido recuperar la contraseña. Comprueba que el correo que has indicado sea correcto')
            })
    }

    return (
        <React.Fragment>
            <Wrapper>
                <Helmet title="Email de recuperación"/>
                <div style={{width: '100%', alignItems: 'center', display: 'flex'}}>
                    <Brand/>
                </div>


                {!sentEmail && <form noValidate style={{width: '100%'}} onSubmit={handleSubmit}>
                    <Typography component="h2" variant="body1" align="center">
                        Introduce tu email para que enviemos el correo de recuperación de contraseña
                    </Typography>
                    <TextField
                        type="email"
                        name="email"
                        label="Correo electrónico"
                        style={{fontSize: 16}}
                        //value={email}
                        //onChange={event => setEmail(event.target.value)}
                        fullWidth
                        my={2}
                    />
                    <Button
                        style={{marginTop: 20}}
                        disabled={isUpdating}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Enviar email
                    </Button>
                </form>}
                {sentEmail && <div style={{width: '100%', textAlign: "center"}}>
                    <h2>
                        ¡Email enviado!
                    </h2>
                    <p>
                        Revisa tu correo para cambiar la contraseña.
                    </p>
                </div>
                }
            </Wrapper>
        </React.Fragment>
    )
}

export default RecoverEmail;
