import {LOCAL_TOKEN_KEY} from "../../../utils/constants";


export const loginUserFormatterFromApi = (data) => {
    console.log(data);
    data.role.map((permit) => {
        localStorage.setItem(permit, permit);
    })

    localStorage.setItem(LOCAL_TOKEN_KEY.USER_NAME, data.userName)

    return {
        userName: data.userName
    }
}
