import axiosService from "../../axiosService";


export const getMonthMenuApi = (month, year) => {
    const url = `/cms/menus/${month}?year=${year}`;
    return axiosService.get(url)
};

export const getWeekMenuApi = (week, year) => {
    const url = `/cms/menus/week/${week}?year=${year}`;
    return axiosService.get(url)
}


export const updateweekMenuApi = (week, year, data) => {
    const url = `/cms/menus/week/${week}?year=${year}`;
    return axiosService.put(url, data)
}
