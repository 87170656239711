import axios from 'axios';


const axiosService = axios.create({
    baseURL: "/api",
    responseType: "json",
    withCredentials: false,

    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});

export default axiosService;
