import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Button,
    Card as MuiCard,
    Divider as MuiDivider,
    Grid,
    Paper,
    TextField as MuiTextField,
    Typography
} from "@mui/material";
import {spacing} from "@mui/system";
import {deleteIngredientRepository, getIngredientsListRepository} from "../../data/repository/ingredients";
import {DataGrid} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router-dom";
import Searchbar from "../../components/unit/Searchbar";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


const columns = [
    {
        field: "name",
        headerName: "Ingrediente",
        width: 400,
        editable: true,
    },
    {
        field: "familyName",
        headerName: "Tipo de ingrediente",
        width: 400,
        editable: true,
    },
    {
        field: "actions",
        headerName: "Acciones",
        headerAlign: 'center',
        width: 100,
        renderCell: params => {
            console.log(params);

            return (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <button onClick={() => {
                        window.location = `ingredients/${params.id}`
                    }} style={{border: "none", backgroundColor: "transparent"}}><EditIcon/></button>
                </div>
            )

        }
    },

];

function DataGridIngredients() {

    const [ingredientsList, setIngredientsList] = useState([]);
    const [filteredData, setFilteredData] = useState(ingredientsList);
    const [idRowsSelected, setIdRowSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');

    const navigate = useNavigate()

    const getList = () => {
        getIngredientsListRepository()
            .then((response) => {
                console.log(response)
                setIngredientsList(response)
                setFilteredData(response)
                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const TextField = styled(MuiTextField)(spacing);

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        handleFilter()
    }, [search])

    const handleDelete = () => {
        if (window.confirm('¿Seguro que quieres eliminar estos ingredientes? Este cambio no se puede deshacer')) {
            deleteIngredientRepository(idRowsSelected)
                .then((response) => {
                    console.log(response)
                    alert('Ingredientes eliminados correctamente')
                })
                .catch((error) => {
                    console.error(error)
                    alert('No se ha podido eliminar los ingredientes')
                })
        }
    };

    const handleFilter = () => {

        let newIngredientList = ingredientsList.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.familyName.toLowerCase().includes(search.toLowerCase()))
        setFilteredData(newIngredientList);


    }

    return (
        <>
            <div style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 12,
                paddingBottom: 24
            }}>
                <Grid style={{maxWidth: '50%', display: "flex", alignItems: "center", flex: 1}}>
                    <Searchbar placeholder="Buscar ingrediente" name="searchIngredient"
                               onChange={(event) => setSearch(event.target.value)}/>
                </Grid>
                <div style={{maxWidth: 300}}>
                    {(idRowsSelected.length > 0) &&
                        <Button
                            type="submit"
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            Eliminar ingrediente
                        </Button>}
                </div>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("new", {state: {name: "Nuevo ingrediente"}})}
                >
                    Añadir ingrediente
                </Button>
            </div>
            <Card mb={6}>
                <Paper>
                    <div style={{padding: 20}}>
                        <DataGrid
                            onSelectionModelChange={(selected) => setIdRowSelected(selected)}
                            rowsPerPageOptions={[25, 50, 100]}
                            autoHeight
                            rows={filteredData}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                            }}
                            columns={columns}
                            loading={isLoading}
                            checkboxSelection
                            density={'compact'}
                            onRowClick={(params) => {
                                navigate(`${params.row.id}`, {state: {name: params.row.name}})
                            }}
                        />
                    </div>
                </Paper>
            </Card>
        </>
    );
}


function Ingredients() {

    return (
        <React.Fragment>
            <Helmet title="Ingredientes"/>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h3" gutterBottom display="inline">
                    Gestión de ingredientes
                </Typography>

                <Typography variant="h5" gutterBottom display="inline">
                    Aquí puedes controlar todos tus ingredientes
                </Typography>
            </div>

            <Divider my={6}/>

            <DataGridIngredients/>

        </React.Fragment>
    );
}

export default Ingredients;
