import React from "react"
import {
    Button,
    CardMedia,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {parseISO} from "date-fns";
import {DatePicker} from "@mui/lab";
import AutocompleteSelect from "../unit/AutocompleteSelect";
import {CloudUpload} from "@mui/icons-material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/***
 *
 * @param type
 * @param options
 * @returns {JSX.Element}
 * @constructor
 *
 * Example usage:
 *
 * const inputs = {
 *         nom: {
 *             type: CustomTextFieldTypes.INPUT,
 *             options: {
 *                 label: "Nom",
 *                 value: "",
 *                 onChange: handleChangeInputs
 *             }
 *         },
 *         cognom: {
 *             type: CustomTextFieldTypes.INPUT,
 *             options: {
 *                 label: "Cognoms",
 *                 value: "",
 *                 onChange: handleChangeInputs
 *             }
 *         },
 *         nomSencer: {
 *             type: CustomTextFieldTypes.INPUT,
 *             options: {
 *                 label: "Nom sencer",
 *                 value: "",
 *                 onChange: handleChangeInputs
 *             }
 *         },
 *         anyNaixament: {
 *             type: CustomTextFieldTypes.YEAR,
 *             options: {
 *                 label: "Any de naixement",
 *                 value: "",
 *                 onChange: handleChangeInputs
 *             }
 *         },
 *         comarcaNaixament: {
 *             type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
 *             options: {
 *                 label: "Comarca de naixement",
 *                 value: "",
 *                 onChange: handleChangeInputs,
 *                 dropdownOptions: comarques
 *             }
 *         },
 *         poblacioNaixament: {
 *             type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
 *             options: {
 *                 label: "Població de naixement",
 *                 value: "",
 *                 onChange: handleChangeInputs,
 *                 dropdownOptions: poblacions
 *             }
 *         },
 *         anyMort: {
 *             type: CustomTextFieldTypes.YEAR,
 *             options: {
 *                 label: "Any de mort",
 *                 value: "",
 *                 onChange: handleChangeInputs
 *             }
 *         },
 *         comarcaMort: {
 *             type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
 *             options: {
 *                 label: "Comarca de mort",
 *                 value: "",
 *                 onChange: handleChangeInputs,
 *                 dropdownOptions: comarques
 *             }
 *         },
 *         poblacioMort: {
 *             type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
 *             options: {
 *                 label: "Població de mort",
 *                 value: "",
 *                 onChange: handleChangeInputs,
 *                 dropdownOptions: poblacions
 *             }
 *         },
 *         tipus: {
 *             type: CustomTextFieldTypes.SELECT,
 *             options: {
 *                 label: "Tipus de compositor",
 *                 value: "normal",
 *                 onChange: handleChangeInputs,
 *                 dropdownOptions: getTipusCompositor(tipusCompositorJson)
 *             }
 *         },
 *         esDona: {
 *             type: CustomTextFieldTypes.CHECKBOX,
 *             options: {
 *                 label: "És dona?",
 *                 value: false,
 *                 onChange: handleChecked
 *             }
 *         },
 *     }
 *
 *
 *
 *      {Object.keys(inputs).map((key, index) => {
 *                                             const input = inputs[key];
 *                                             return (
 *                                                 <Grid key={index} item md={4} style={{minWidth: 224}}>
 *                                                     <CustomTextField type={input.type}
 *                                                                      options={{...input.options, name: key}}/>
 *                                                 </Grid>
 *                                             )
 *                                         }
 *                                     )}
 *
 */


const CustomTextField = ({type, options = {}}) => {

    if (type === CustomTextFieldTypes.BLANK) {
        return <div style={{flex: 1}}/>
    }
    if (type === CustomTextFieldTypes.INPUT) {
        return (
            <>
                <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{options.label}</Typography>
                <TextField
                    placeholder={options.label}
                    name={options.name}
                    fullWidth
                    type="string"
                    value={options.value}
                    onChange={options.onChange}
                />
            </>
        )
    }

    if (type === CustomTextFieldTypes.LARGE_INPUT) {
        return (
            <>
                <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{options.label}</Typography>
                <TextField
                    multiline
                    rows={4}
                    placeholder={options.label}
                    name={options.name}
                    fullWidth
                    type="string"
                    value={options.value}
                    onChange={options.onChange}
                />
            </>
        )
    }

    if (type === CustomTextFieldTypes.YEAR) {
        return <>
            <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{options.label}</Typography><DatePicker
            name={options.name}
            fullWidth
            views={["year"]}
            value={parseISO(options.value)}
            onChange={(value) =>
                options.onChange({
                    target: {
                        name: options.name,
                        value: value?.getFullYear().toString(),
                    },
                })
            }
            minDate={new Date("1800")}
            renderInput={(params) => <TextField fullWidth {...params} placeholder={options.label} error={false}/>}
        />
        </>
    }
    if (type === CustomTextFieldTypes.AUTOCOMPLETE_SELECT) {
        return (
            <div style={{display: 'flex', alignItems: "center"}}>
                <AutocompleteSelect
                    label={options.label}
                    value={options.value}
                    options={options.dropdownOptions}
                    onChange={(value) =>
                        options.onChange({
                            target: {
                                name: options.name,
                                value: value,
                                options: options.dropdownOptions
                            },
                        })
                    }
                />
                {options.openNewTabOnSelect &&
                    <a target={'_blank'} href={options?.value?.inputValue} style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 10,
                        cursor: 'pointer'
                    }}>
                        <OpenInNewIcon style={{color: "#EC9F05"}}/>
                    </a>
                }
            </div>
        )
    }
    if (type === CustomTextFieldTypes.SELECT) {
        return (
            <>
                <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{options.label}</Typography>
                <div style={{ display: 'flex' }}>
                    <FormControl style={{width: '100%'}}>
                        <Select
                            name={options.name}
                            fullWidth
                            value={options.value}
                            placeholder={options.label}
                            displayEmpty
                            onChange={options.onChange}
                        >
                            {options.dropdownOptions?.map((option) =>
                                <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {options.openNewTabOnSelect &&
                        <a target={'_blank'} href={options.value} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10, cursor: 'pointer'}}>
                            <OpenInNewIcon style={{color: "#EC9F05"}}/>
                        </a>
                    }
                </div>
            </>
        )
    }
    if (type === CustomTextFieldTypes.MULTI_SELECT) {
        return (
            <>
                <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{options.label}</Typography>
                <FormControl style={{width: '100%'}}>
                    <Select
                        name={options.name}
                        fullWidth
                        multiple
                        style={{minHeight: 65}}
                        value={options.value}
                        placeholder={options.label}
                        displayEmpty
                        input={<OutlinedInput id="select-multiple-chip"/>}
                        renderValue={(selected) => (
                            <div style={{display: "flex", gap: 4, flexWrap: 'wrap'}}>
                                {selected.map((value) => {
                                    let text = options.dropdownOptions?.find((option) => option.value === value);
                                    return (
                                        <Chip key={value} label={text.label}/>
                                    )
                                })}
                            </div>
                        )}
                        onChange={options.onChange}
                    >
                        {options.dropdownOptions?.map((option) =>
                            <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </>
        )
    }
    if (type === CustomTextFieldTypes.CHECKBOX) {
        return (
            <>
                <Typography style={{fontWeight: "bold", paddingBottom: 8}}>{options.label}</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={options.value}
                            onChange={(event) => {
                                options.onChange({
                                    target: {
                                        value: event.target.checked,
                                        name: event.target.name
                                    }
                                })
                            }}
                            name={options.name}
                        />
                    }
                    label={options.label}
                />
            </>
        )
    }

    if (type === CustomTextFieldTypes.IMAGE) {
        return (
            <>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Typography style={{fontWeight: "bold", paddingBottom: 8, flex: 1}}>Imagen</Typography>
                    <input
                        accept="image/*"
                        style={{display: "none"}}
                        onChange={options.onChange}
                        id="raised-button-file"
                        multiple
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" color="primary" component="span">
                            <CloudUpload mr={2}/> Seleccionar
                        </Button>

                    </label>
                </div>
                <Typography variant="caption" display="block" gutterBottom>
                    {options.subtitle}
                </Typography>

                <CardMedia
                    component="img"
                    height="140"
                    image={options.value}
                />
            </>
        )
    }

    return <></>

}

export const CustomTextFieldTypes = {
    INPUT: "input",
    BLANK: "blank",
    YEAR: "year",
    AUTOCOMPLETE_SELECT: "autocomplete_select",
    SELECT: "select",
    MULTI_SELECT: "multi_select",
    CHECKBOX: "checkbox",
    LARGE_INPUT: "large_input",
    IMAGE: "image"
}

export default CustomTextField;
