import React from "react";
import styled, {withTheme} from "styled-components/macro";
import Chart from "react-chartjs-2";
import {MoreVertical} from "react-feather";
import {rgba} from "polished";

import {Card as MuiCard, CardContent, CardHeader, IconButton,} from "@mui/material";
import {spacing} from "@mui/system";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
    height: 340px;
    width: 100%;
`;

const BarChart = ({theme}) => {
    const firstDatasetColor = theme.palette.secondary.main;
    const secondDatasetColor = rgba(theme.palette.secondary.main, 0.33);

    const data = {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
        datasets: [
            {
                label: "Mobile",
                backgroundColor: firstDatasetColor,
                borderColor: firstDatasetColor,
                hoverBackgroundColor: firstDatasetColor,
                hoverBorderColor: firstDatasetColor,
                data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            },
            {
                label: "Desktop",
                backgroundColor: secondDatasetColor,
                borderColor: secondDatasetColor,
                hoverBackgroundColor: secondDatasetColor,
                hoverBorderColor: secondDatasetColor,
                data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                borderRadius: 6,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                grid: {
                    display: false,
                },
                stacked: true,
                ticks: {
                    stepSize: 20,
                    fontColor: theme.palette.text.secondary,
                },
            },

            x: {
                stacked: true,
                grid: {
                    color: "transparent",
                },
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
            },
        },
    };

    return (
        <Card mb={6}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" size="large">
                        <MoreVertical/>
                    </IconButton>
                }
                title="Mobile / Desktop"
            />

            <CardContent>
                <ChartWrapper>
                    <Chart type="bar" data={data} options={options}/>
                </ChartWrapper>
            </CardContent>
        </Card>
    );
};

export default withTheme(BarChart);
