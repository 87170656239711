import _ from 'lodash';
import moment from "moment";

export const getGroceryListFormatterFromApi = (data, week, year) => {
    const date = moment([year]).add(week, 'weeks').startOf('isoWeeks');
    const endOfWeek = moment(date).endOf('isoWeek');
    const menuTitle = `<h2>Menú del ${moment(date).format('DD-MM')} al ${moment(endOfWeek).format('DD-MM')}</h2>`

    const orderedData = _.orderBy(data, ['ingredientFamilyName'], ['asc']);
    const groupedGroceries = _.groupBy(orderedData, 'ingredientFamilyName');

    const formatted = _.map(groupedGroceries, (groceries, familyName) => {
        const orderedGroceries = _.orderBy(groceries, ['name'], ['asc']);
        const formattedGroceriesData = _.map(orderedGroceries, (grocery) => {
            return `<li>${grocery.quantity} ${grocery.measureUnit} de ${grocery.name?.toLowerCase()}</li>`;
        });

        return {
            title: `<h3>${familyName}</h3>`,
            data: `<ul>${formattedGroceriesData.join('')}</ul>`
        }
    });

    const content = formatted.map(item =>  `${item.title}${item.data}`).join('')

    return menuTitle.concat(content)
}
