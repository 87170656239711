import React from "react";
import styled from "styled-components/macro";
import {NavLink} from "react-router-dom";

import {spacing} from "@mui/system";
import {green} from "@mui/material/colors";

import {Box as MuiBox, Chip, Drawer as MuiDrawer, ListItemButton,} from "@mui/material";

import {ReactComponent as Logo} from "../../vendor/logo_white.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 174px;
  height: 115px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const Sidebar = ({items, showFooter = true, ...rest}) => {
    return (
        <Drawer variant="permanent" {...rest}>
            <Brand component={NavLink} to="/dashboard/home">
                <BrandIcon/>{" "}
            </Brand>
            <SidebarNav items={items}/>
            {!!showFooter && <Footer/>}
        </Drawer>
    );
};

export default Sidebar;
