import React from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {Paper, Typography} from "@mui/material";

import {ReactComponent as Logo} from "../../vendor/logo_black.svg";
import SignInComponent from "../../components/auth/SignIn";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 174px;
  height: 115px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
    return (
        <React.Fragment>
            <Wrapper>
                <Helmet title="Iniciar sesión"/>
                <div style={{width: '100%', alignItems: 'center', display: 'flex'}}>
                    <Brand/>
                </div>

                <Typography component="h2" variant="body1" align="center">
                    Inicia sesión para continuar
                </Typography>

                <SignInComponent/>
            </Wrapper>
        </React.Fragment>
    );
}

export default SignIn;
