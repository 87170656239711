import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {Helmet} from "react-helmet-async";

import {
    Alert,
    Breadcrumbs,
    Button,
    Card as MuiCard,
    createFilterOptions,
    Divider as MuiDivider,
    Grid,
    Link,
    Paper,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {spacing} from "@mui/system";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import ActivityButton from "../../components/unit/ActivityButton";
import CustomTextField, {CustomTextFieldTypes} from "../../components/composed/CustomTextField";
import {DataGrid} from "@mui/x-data-grid";
import {
    deleteIngredientRepository,
    getAllergensRepository,
    getCookingTypesRepository,
    getIngredientFamiliesRepository,
    getIngredientRepository,
    saveIngredientRepository
} from "../../data/repository/ingredients";
import _ from "lodash";
import {Autocomplete} from "@mui/lab";
import Modal from "../../components/unit/Modal";
import {makeStyles} from "@mui/styles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const emptyRow = {
    cookingTypeId: 1,
    proteins: 0,
    fats: 0,
    carbohydrates: 0,
    kcal: 0
}

function IngredientsDetail() {

    const useStyles = makeStyles({
        root: {
            "& .orange": {
                backgroundColor: "#fbf0ab",
            },
            "& .blue": {
                backgroundColor: "#e8f5ff",
            },
        },
    });

    const classes = useStyles();

    const {id} = useParams();
    const {state} = useLocation();

    const navigate = useNavigate();

    const [ingredientName, setIngredientName] = useState(state?.name);

    const [snackbar, setSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarText, setSnackbarText] = useState("");

    const [newCookingType, setNewCookingType] = useState("")

    const [ingredient, setIngredient] = useState({});
    const [cookingTypes, setCookingTypes] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const [inputs, setInputs] = useState({
        name: {
            type: CustomTextFieldTypes.INPUT,
            options: {
                label: "Nombre ingrediente",
                value: "",
                onChange: handleChangeInputs
            }
        },
        familyId: {
            type: CustomTextFieldTypes.AUTOCOMPLETE_SELECT,
            options: {
                label: "Familia ingrediente",
                value: "",
                onChange: handleChangeAutocompleteInput,
                dropdownOptions: []
            }
        },
        allergenIds: {
            type: CustomTextFieldTypes.MULTI_SELECT,
            options: {
                label: "Alérgenos",
                value: [],
                onChange: handleChangeInputs,
                dropdownOptions: [{value: 1, label: "aaa"}, {value: 2, label: "bbaaa"}]
            }
        },
    })

    const [columns, setColumns] = useState([
        {
            field: 'id',
            hide: true
        },
        {
            field: "cookingTypeId",
            headerName: "Cocción",
            minWidth: 250,
            flex: 1,
            type: 'singleSelect',
            valueOptions: [],
            editable: true,
            valueFormatter: (params) => {
                const col = params.api.getColumn('cookingTypeId');
                return col?.valueOptions?.find((option) => option.id === params.value)?.label
            },
            /*renderCell: (params) => {
                console.log(params)
                const col = params.colDef;
                let valueLabel = col?.valueOptions?.find((option) => option.value === params.value)?.label;
                if (valueLabel === undefined) valueLabel = "";

                const getOptionLabel = (option, col) => {
                    if (option.label) return option.label;
                    if (typeof option === "string") {
                        return option;
                    }

                    if (typeof option === "number") {
                        if (col?.valueOptions.length === 0) return "";
                        return col?.valueOptions?.find((item) => item.id === option)?.label;
                    }

                    if (option.inputValue) {
                        return option.inputValue;
                    }

                    return option;
                }

                function handleSelect(event, newValue) {
                    const newId = newValue;

                    console.log(newValue)

                    params.row.cookingType = newValue.id;

                    if (typeof newValue === "string") {
                        params.row.cookingType = newValue;
                    } else if (newValue && newValue.inputValue) {
                        params.row.cookingType = newValue;

                    }
                }
                const filter = createFilterOptions();

                return <Autocomplete
                    value={params.value}
                    options={col.valueOptions}
                    onChange={handleSelect}

                    isOptionEqualToValue={(option, val) => {
                        return option.id.toString() === val.toString()
                    }
                    }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        console.log(params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.label);

                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                id: null,
                                label: `Añadir "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    getOptionLabel={(option) => getOptionLabel(option, col)}
                    fullWidth
                    renderInput={(params) =>
                        <TextField {...params}
                            onChange={(e) => console.log("onchange", e)}
                                   style={{border: 'none'}}/>}
                />
            } */
        },
        {
            field: "proteins",
            headerName: "Proteinas",
            type: 'number',
            width: 100,
            editable: true,
            cellClassName: (params) => {
                if (params.row.proteins === 0) {
                    return 'orange'
                }


            },
        },
        {
            field: "fats",
            headerName: "Grasas",
            type: 'number',
            width: 100,
            editable: true,
            cellClassName: (params) => {
                if (params.row.fats === 0) {
                    return 'orange'
                }


            },
        },
        {
            field: "carbohydrates",
            headerName: "Carbohidratos",
            type: 'number',
            width: 120,
            editable: true,
            cellClassName: (params) => {
                if (params.row.carbohydrates === 0) {
                    return 'orange'
                }


            },
        },
        {
            field: "kcal",
            headerName: "Kcal",
            type: 'number',
            minWidth: 100,
            editable: true,
            cellClassName: (params) => {
                if (params.row.kcal === 0) {
                    return 'orange'
                }
            },
        },

    ]);
    const [rows, setRows] = useState([]);

    const [idsRowsSelected, setIdsRowsSelected] = useState([]);

    const filter = createFilterOptions();

    const [modal, setModal] = useState(false);


    useEffect(() => {
        if (id !== "new") fetchAllData()
        else {
            getCookingTypesRepository()
                .then((cookingTypes) => {
                    updateData({cookingTypes});
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setSnackbarSeverity("error");
                    setSnackbarText("Ha habido un error obteniendo tipos de cocción");
                    setSnackbar(true);
                    setIsLoading(false)
                })
            getAllergensRepository()
                .then((allergens) => {
                    updateData({allergens});
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setSnackbarSeverity("error");
                    setSnackbarText("Ha habido un error obteniendo alérgenos");
                    setSnackbar(true);
                    setIsLoading(false)
                })
            getIngredientFamiliesRepository()
                .then((ingredientFamilies) => {
                    updateData({ingredientFamilies});
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.error(err)
                    setSnackbarSeverity("error");
                    setSnackbarText("Ha habido un error obteniendo las famílias de ingredientes");
                    setSnackbar(true);
                    setIsLoading(false)
                })
        }
    }, [id]);

    const fetchAllData = () => {
        getIngredientRepository(id)
            .then((data) => {
                updateData(data)
                setIsLoading(false)
            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Ha habido un error obteniendo el ingrediente");
                setSnackbar(true);
                setIsLoading(false)
            })

    }

    const updateData = ({ingredient, ingredientFamilies, allergens, cookingTypes}) => {
        if (ingredientFamilies || allergens) {
            const newInputs = {...inputs};
            Object.keys(newInputs).forEach((key) => {
                if (ingredient) {
                    newInputs[key].options.value = ingredient[key];
                }
                if (ingredientFamilies && key === "familyId") {
                    newInputs[key].options.dropdownOptions = ingredientFamilies;
                }
                if (allergens && key === "allergenIds") {
                    newInputs[key].options.dropdownOptions = allergens;
                }
            })
            setInputs(newInputs)
        }

        if (ingredient) {
            setIngredientName(ingredient.name);
            setIngredient(ingredient)
            setRows(ingredient.nutritionalInfo)
        }
        if (cookingTypes) {
            setCookingTypes(cookingTypes);
            updateCookingTypesOnTable(cookingTypes);
        }
    }

    function updateCookingTypesOnTable(newCookingTypes) {
        const newColumns = _.cloneDeep(columns);
        for (const col of newColumns) {
            if (col.field === 'cookingTypeId') {
                col.valueOptions = newCookingTypes
                break;
            }
        }
        setColumns(newColumns);
    }

    const [addedNewCookingTypes, setAddedNewCookingTypes] = useState([]);

    const addNewCookingType = () => {
        const exists = cookingTypes.some((el) => el.label === newCookingType.label)
        if (exists) return;

        const maxId = Math.max.apply(Math, cookingTypes.map(function (o) {
            return o.id;
        }))

        const newType = {
            id: maxId + 1,
            value: maxId + 1,
            label: newCookingType.label
        }

        setAddedNewCookingTypes([...addedNewCookingTypes, newType])
        setCookingTypes([...cookingTypes, newType])

        setNewCookingType("");

        updateCookingTypesOnTable([...cookingTypes, newType])
    }

    function handleChangeInputs(event) {
        const value = event.target.value;
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        newInputs[name].options.value = value;
        setInputs(newInputs)
    }

    function handleChangeAutocompleteInput(event) {
        const value = event.target.options.find(item => item.value === event.target.value?.value);
        const name = event.target.name;
        if (!inputs[name]) return;
        const newInputs = {...inputs};
        if (!!value) {
            newInputs[name].options.value = value;
        } else {
            newInputs[name].options.value = "";
        }
        setInputs(newInputs)
    }

    const addNewRow = () => {
        const maxId = Math.max.apply(Math, rows.map(function (o) {
            return o.id;
        }))

        const nextId = rows.length > 0 ? maxId + 1 : 0;

        setRows([...rows, {...emptyRow, id: nextId}]);
    }

    const deleteRows = () => {
        const res = window.confirm("¿Seguro que quieres borrar la información nutricional seleccionada?")
        if (!res) return;
        let newRows = [];
        for (let row of rows) {
            if (!idsRowsSelected.some((id) => id === row.id)) {
                newRows.push(row);
            }
        }
        setRows(newRows);
    }

    const deleteIngredient = () => {
        const res = window.confirm("¿Seguro que quieres borrar el ingrediente? Esta operación no es reversible y sólo se ejecutará si el ingrediente no está asociado a ninguna receta.")
        if (!res) return;
        deleteIngredientRepository([id])
            .then(() => {
                setSnackbarSeverity("success");
                setSnackbarText("Ingrediente eliminado correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                navigate(`/dashboard/ingredients`);

            })
            .catch(() => {
                setSnackbarSeverity("error");
                setSnackbarText("Ha habido un error borrando el ingrediente");
                setSnackbar(true);
                setIsUpdating(false);
            })
    }

    const save = () => {
        setIsUpdating(true);
        const ingredientId = id === "new" ? null : id
        let ingredientSave = {...ingredient};
        Object.keys(inputs).forEach((key) => {
            ingredientSave[key] = inputs[key].options.value;
        })
        ingredientSave.nutritionalInfo = rows
        saveIngredientRepository(ingredientId, ingredientSave, addedNewCookingTypes)
            .then((ingredient) => {
                setSnackbarSeverity("success");
                setSnackbarText("Ingrediente guardado correctamente");
                setSnackbar(true);
                setIsUpdating(false);
                if (id === "new") {
                    navigate(`/dashboard/ingredients/${ingredient.id}`);
                } else {
                    setIngredient(ingredient)
                    fetchAllData()
                }
            })
            .catch((err) => {
                setSnackbarSeverity("error");
                setSnackbarText(err);
                setSnackbar(true);
                setIsUpdating(false);
            })
    }

    const handleRowEdited = (row) => {
        let index = rows.findIndex(r => r.id === row.id)
        rows[index][row.field] = row.value
        setRows([...rows])
    }

    return (
        <React.Fragment>
            <Helmet title="Recetas"/>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Añadir/editar ingrediente
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={2}>
                        <Link component={NavLink} to="/dashboard">
                            CMS
                        </Link>
                        <Link component={NavLink} to="/dashboard/ingredients">
                            Ingredientes
                        </Link>
                        <Typography>{ingredientName}</Typography>
                    </Breadcrumbs>
                </div>

                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 12}}>
                    {id !== "new" && <ActivityButton title={"Borrar"} variant={'outlined'} onClick={deleteIngredient}/>}
                    <ActivityButton title={"Guardar"} isUpdating={isUpdating} onClick={save}/>
                </div>

            </div>

            <Divider my={3}/>

            <Grid container spacing={6}>
                {Object.keys(inputs).map((key, index) => {
                        const input = inputs[key];
                        return (
                            <Grid key={index} item md={4} style={{minWidth: 224}}>
                                <Card style={{padding: 24}}>
                                    <CustomTextField type={input.type} options={{...input.options, name: key}}/>
                                </Card>
                            </Grid>
                        )
                    }
                )}
            </Grid>

            <div style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                marginTop: 32,
                gap: 8
            }}>
                <div style={{flex: 1}}>
                    <Typography variant="h4" style={{marginBottom: 4}}>
                        Información nutricional
                    </Typography>
                    <Typography variant="p">
                        Esta es la información nutricional por cada 100 g. de este ingrediente en función de la cocción.
                    </Typography>
                </div>

            </div>


            <Divider my={3}/>

            <Card mb={6}>
                <Paper>
                    <div style={{padding: 20}} className={classes.root}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            gap: 8,
                        }}>
                            <div style={{flex: 1}}>
                                <ActivityButton title={"Añadir tipo de cocción"} variant={'outlined'}
                                                onClick={() => setModal(true)}/>
                            </div>
                            {idsRowsSelected.length > 0 &&
                                <ActivityButton title={"Borrar"} variant={'outlined'}
                                                color={'error'} onClick={deleteRows}/>}
                            <ActivityButton title={"Añadir fila"} variant={'outlined'} onClick={addNewRow}/>
                        </div>
                        <DataGrid
                            style={{marginTop: 20}}
                            onSelectionModelChange={(selected) => setIdsRowsSelected(selected)}
                            rowsPerPageOptions={[25, 50, 100]}
                            autoHeight
                            rows={rows}
                            columns={columns}
                            loading={isLoading}
                            disableSelectionOnClick
                            onCellEditCommit={handleRowEdited}
                            checkboxSelection
                        />
                    </div>
                </Paper>
            </Card>

            <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarText}
                </Alert>
            </Snackbar>


            <Modal open={modal} onClose={() => setModal(false)}>
                <Typography id="transition-modal-title" variant="h3" component="h2">
                    Añadir nuevo tipo de cocción
                </Typography>
                <Typography id="transition-modal-description" sx={{mt: 2}}>
                    Añade un tipo de cocción no existente introduciendo el tipo. Si ya existe no se añadirá.
                </Typography>
                <Typography id="transition-modal-description" sx={{mt: 2}} color={'error'} style={{marginBottom: 12}}>
                    Evitar añadir tipos muy similares o muy detallados, ej: Horno de gas, Horno eléctrico.
                </Typography>

                <Autocomplete
                    value={newCookingType}
                    fullWidth
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setNewCookingType({
                                label: newValue.trim(),
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setNewCookingType({
                                label: newValue.inputValue.trim(),
                            });
                        } else {
                            setNewCookingType(newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const {inputValue} = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `Añadir "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={cookingTypes}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                />

                <div style={{display: "flex", flex: 1, gap: 12, justifyContent: "center", marginTop: 20}}>
                    <Button variant={"contained"} onClick={() => {
                        setModal(false);
                        addNewCookingType()
                    }}>Aceptar</Button>
                    <Button variant={"outlined"} onClick={() => setModal(false)}>Cancelar</Button>
                </div>
            </Modal>

        </React.Fragment>
    );
}

export default IngredientsDetail;

